import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GeneralTitle } from "../../components/GeneralTitle";
import { Layout } from "../../components/Layout";
import { Navbar } from "../../components/Navbar";
import { ProfileOverview } from "../../components/PagesContent/ProfileOverview";
import { getUser } from "../../selectors";

export const ProfileOverviewPage = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const topBar = (
    <GeneralTitle
      mainText={user ? user.name : "Unknown"}
      secondaryText={t("profile")}
      rightComponent={<div />}
    />
  );
  const navBar = <Navbar />;

  return (
    <Layout topBar={topBar} navBar={navBar} fixedTop={false}>
      <ProfileOverview />
    </Layout>
  );
};
