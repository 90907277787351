import { userReducer, applicationReducer } from "../reducers";

import { citizenApi } from "../api/citizenApi";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { indexListener, listenerMiddleware } from "../middleware";

const store = configureStore({
  reducer: {
    user: userReducer,
    application: applicationReducer,
    [citizenApi.reducerPath]: citizenApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(citizenApi.middleware),
});

indexListener();

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
