import { Home } from "../../components/PagesContent/Home";
import { Layout } from "../../components/Layout";
import { Navbar } from "../../components/Navbar";
import { HomeTitle } from "../../components/HomeTitle";

export const HomePage = () => {
  const topBar = <HomeTitle />;
  const navBar = <Navbar />;

  return (
    <Layout topBar={topBar} navBar={navBar} fixedTop={false}>
      <Home />
    </Layout>
  );
};
