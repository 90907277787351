import { useEffect } from "react";
import { getFcmToken, useAppDispatch } from "../../utils";
import { setDeviceToken } from "../../reducers";

export const useRetrieveFcmToken = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    // save FCM token to store when component loads
    getFcmToken(message => {
      if (message.data) {
        dispatch(setDeviceToken(message.data));
      }
    });
  }, []);
};
