import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "../../../constants";
import { AuthBoxHeader } from "../../AuthBoxHeader";
import { Box } from "../../Box";
import { Button } from "../../Button";

import styles from "./HowToRegister.module.scss";

export const HowToRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box className={styles.howToRegisterContainer}>
      <AuthBoxHeader title={t("howToRegisterTitle")} />
      <p>{t("howToRegisterText")}</p>
      <Button buttonText={t("back")} onClick={() => navigate(LOGIN_PATH)} />
    </Box>
  );
};
