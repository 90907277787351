import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "../Layout";
import styles from "./NoInternetConnection.module.scss";

export const NoInternetConnection: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOnline, setOnlineState] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setOnlineState(navigator.onLine);
    const setOnline = () => setOnlineState(true);
    const setOffline = () => setOnlineState(false);

    // event listeners to update the state
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  if (isOnline) {
    return <>{children}</>;
  } else {
    return (
      <Layout>
        <div className={styles.center}>
          <h1>{t("offlineTitle")}</h1>
          <p>{t("offlineText")}</p>
        </div>
      </Layout>
    );
  }
};
