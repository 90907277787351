import { WasteTypes } from "./enums";

export const wasteTypeToColorMapping = new Map<WasteTypes, string>([
  [WasteTypes.bio, "#a07b4b"],
  [WasteTypes.plastics, "#f4c311"],
  [WasteTypes.paper, "#0682fe"],
  [WasteTypes.metal, "#ea5230"],
  [WasteTypes.glass, "#4ab51d"],
  [WasteTypes.mixed, "#35373b"],
  [WasteTypes.vkm, "#ffa500"],
]);
