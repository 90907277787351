export enum Month {
  january = "january",
  february = "february",
  march = "march",
  april = "april",
  may = "may",
  june = "june",
  july = "july",
  august = "august",
  september = "september",
  october = "october",
  november = "november",
  december = "december",
}

export enum WasteTypes {
  bio = "bio",
  paper = "paper",
  plastics = "plastics",
  glass = "glass",
  mixed = "mixed",
  vkm = "vkm",
  metal = "metal",
  collectionyard = "collectionyard",
}

export enum WasteTypesMap {
  bio = 1,
  paper = 2,
  plastics = 3,
  glass = 4,
  mixed = 6,
  vkm = 8,
  metal = 10,
  collectionyard = 99,
}

export enum ContainerTypes {
  p120 = "p120",
  p240 = "p240",
  p1100 = "p1100",
  kp7 = "kp7",
  belltype = "belltype",
  p140 = "p140",
  p360 = "p360",
  p660 = "p660",
  p770 = "p770",
  p1300 = "p1300",
  p3000 = "p3000",
  p5000 = "p5000",
  p110 = "p110",
  p80 = "p80",
  p60 = "p60",
  collectionyard = "collectionyard",
}

export enum Icons {
  arrowLeft = "arrowLeft",
  arrowRight = "arrowRight",
  arrows = "arrows",
  bell = "bell",
  bin = "bin",
  ewaLogo = "ewaLogo",
  home = "home",
  location = "location",
  logoKoala = "logoKoala",
  members = "members",
  menuTrash = "menuTrash",
  person = "person",
  phoneContact = "phoneContact",
  recycle = "recycle",
  separate = "separate",
  stats = "stats",
  trianlge = "trianlge",
  username = "username",
  weight = "weight",
  weightOverall = "weightOverall",
  lock = "lock",
  logout = "logout",
  offline = "offline",
  serverError = "serverError",
  card = "card",
  language = "language",
  check = "check",
  notification = "notification",
  mail = "mail",
  password = "password",
  calendar = "calendar",
  customMenuTrash = "customMenuTrash",
}

export enum Images {
  apple_badge = "apple_badge",
  google_play_badge = "google_play_badge",
  noResults = "noResults",
}

export enum Errors {
  serverError = "serverError",
  noConnection = "noConnection",
}

export enum Languages {
  sk = "sk",
  cs = "cs",
  hu = "hu",
}

export enum Asnwer {
  yes = "yes",
  no = "no",
}

export enum WagonNotificationDay {
  onEvent = "onEvent",
  twoDaysBeforeEvent = "twoDaysBeforeEvent",
  oneDayBeforeEvent = "oneDayBeforeEvent",
}

export enum WagonNotificationDayValue {
  onEvent = 0,
  oneDayBeforeEvent = 24,
  twoDaysBeforeEvent = 48,
}

export const monthsArray = Object.keys(Month) as (keyof typeof Month)[];

export const containerTypesArray = Object.keys(ContainerTypes) as (keyof typeof ContainerTypes)[];

export const languagesArray = Object.keys(Languages) as (keyof typeof Languages)[];

export function getWasteTypeName(value: number) {
  return (
    (Object.keys(WasteTypesMap).find(
      key => WasteTypesMap[key as WasteTypes] === value
    ) as WasteTypes) ?? WasteTypes.mixed
  );
}
