import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Icon, IconType } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { INDEX_PATH } from "../../constants";
import { Errors } from "../../utils";
import styles from "./ErrorPage.module.scss";

export const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className={styles.center}>
        <Icon name={Errors.serverError} type={IconType.default} />
        <h1>{t("serverErrorTitle")}</h1>
        <p>{t("serverErrorText")}</p>
        <Button
          buttonText={t("backToMainPage")}
          onClick={() => (window.location.href = INDEX_PATH)}
        />
      </div>
    </Layout>
  );
};
