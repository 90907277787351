import React from "react";
import { Box, BoxProps, BoxVariant } from "../Box";
import styles from "./BoxWithDividerLines.module.scss";

export interface IterableItem {
  id: number | string;
  element: React.ReactNode;
}

interface BoxWithDividerLinesProps extends BoxProps {
  items: IterableItem[];
}

export const BoxWithDividerLines: React.FC<BoxWithDividerLinesProps> = ({
  className,
  rounded = true,
  variant = BoxVariant.primary,
  items,
  style,
}) => {
  return (
    <Box {...{ style, className, rounded, variant }}>
      {items.map((item, index) => {
        const isNotLast = index !== items.length - 1;

        return (
          <div key={item.id}>
            {item.element}
            {isNotLast && <div className={styles["border-line"]} />}
          </div>
        );
      })}
    </Box>
  );
};
