import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WasteCalendarEvent } from "../../types";
import styles from "./CalendarDay.module.scss";
import {
  transformDayShortcut,
  formattedDate,
  wasteTypeToColorMapping,
  getLocalizedShortWeekDay,
  getDayOfMonth,
} from "../../utils";
import { VerticalBar } from "../VerticalBar";
interface CalendarDayProps {
  event: WasteCalendarEvent;
}

export const CalendarDay: React.FC<CalendarDayProps> = ({ event }) => {
  const { t } = useTranslation();
  const date = useMemo(() => new Date(event.date), [event.date]);
  const dayShortcut = getLocalizedShortWeekDay(date);

  return (
    <div className={styles.container}>
      <VerticalBar color={wasteTypeToColorMapping.get(event.wasteType) ?? "#fff"} />
      <div className={styles["date-info"]}>
        <div className={styles["day-in-month"]}>{getDayOfMonth(date)}</div>
        <div className={styles["day-of-week"]}>{transformDayShortcut(dayShortcut)}</div>
      </div>
      <div>
        <div className={`${styles["collection-info"]}`}>
          <div className={styles["collection-label"]}>
            {t(`enums.wasteTypes.${event.wasteType}`)}
          </div>
          <div className={styles["collection-date"]}>{formattedDate(date)}</div>
        </div>
      </div>
    </div>
  );
};
