import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetContainerCollectionsQuery } from "../../api/citizenApi";
import { ContentLoader } from "../../components/ContentLoader";
import { GeneralTitle } from "../../components/GeneralTitle";
import { Layout } from "../../components/Layout/Layout";
import { Navbar } from "../../components/Navbar";
import { NavigateBackWithContent } from "../../components/NavigateBackWithContent";
import { WeightHistoryDetail } from "../../components/PagesContent/WeightHistoryDetail";
import { YearSelect } from "../../components/SelectComponent";
import { getSelectedYear } from "../../selectors";
import { containerTypesArray, getWasteTypeName } from "../../utils";

export const WeightHistoryDetailPage = () => {
  const { t } = useTranslation();

  const { containerId } = useParams();
  const selectedYear = useSelector(getSelectedYear);
  const { data: containerHistory, isFetching } = useGetContainerCollectionsQuery({
    year: selectedYear,
    id: containerId!,
  });

  // if (error) {
  //   if ("data" in error && error.data.message.length > 0) throw new Error(error.data.message[0]);
  //   else throw new Error("Something went wrong");
  // }

  if (isFetching || containerHistory === undefined) return <ContentLoader />;

  const contType = containerHistory.info.type
    ? `${t(`enums.containerTypes.${containerTypesArray[containerHistory.info.type - 1]}`)} `
    : "";
  const type = t(`enums.wasteTypes.${getWasteTypeName(containerHistory.info.wasteType)}`);
  const main = contType + type;

  const topBar = (
    <NavigateBackWithContent
      rightComponent={
        <GeneralTitle
          mainText={main}
          secondaryText={containerHistory.info.RFID}
          rightComponent={<YearSelect />}
        />
      }
    />
  );
  const navBar = <Navbar />;

  return (
    <Layout topBar={topBar} navBar={navBar}>
      <WeightHistoryDetail collections={containerHistory.events} />
    </Layout>
  );
};
