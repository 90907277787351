import React from "react";
import styles from "./Box.module.scss";

export interface BoxProps {
  rounded?: boolean;
  variant?: BoxVariant;
  className?: string;
  style?: React.CSSProperties;
}

export enum BoxVariant {
  primary = "primary",
}

export const Box: React.FC<BoxProps> = ({
  className,
  rounded = true,
  variant = BoxVariant.primary,
  children,
  style,
}) => {
  const classNames = [styles.box];
  if (className) classNames.push(className);
  if (rounded) classNames.push(styles.rounded);
  if (variant === BoxVariant.primary) classNames.push(styles.primary);

  return (
    <div style={style} className={classNames.join(" ")}>
      {children}
    </div>
  );
};
