import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../../api/citizenApi";
import { Box } from "../../Box";
import { Button } from "../../Button";
import { IconType } from "../../Icon";
import { InputWithStartIcon } from "../../InputWithStartIcon";
import { getUser } from "../../../selectors";
import { useValueObject } from "../../../utils";
import styles from "./Login.module.scss";
import { LoginRequest, ValueObject } from "../../../types";
import { ASK_FOR_PASSWORD_RESET_PATH, HOME_PATH, HOW_TO_REGISTER_PATH } from "../../../constants";
import { AuthBoxHeader } from "../../AuthBoxHeader";

const initialValueObject: ValueObject = {
  email: "",
  password: "",
};

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const [loginUser] = useLoginUserMutation();
  const props = useValueObject(initialValueObject);

  if (user) {
    return <Navigate to={HOME_PATH} />;
  }

  const canSubmit = props.valueObject.email.length > 0 && props.valueObject.password.length > 0;

  const loginHandler = () => {
    const credentials: LoginRequest = {
      email: props.valueObject.email,
      password: props.valueObject.password,
    };
    loginUser(credentials)
      .unwrap()
      .then(() => navigate(HOME_PATH))
      .catch(() => {
        return;
      });
  };

  return (
    <Box className={styles["login-container"]}>
      <AuthBoxHeader
        title={t("login")}
        linkText={t("howToRegisterTitle")}
        linkTo={HOW_TO_REGISTER_PATH}
      />
      <div className={styles["inputs-container"]}>
        <InputWithStartIcon
          iconName="mail"
          iconType={IconType.gray}
          placeholder="jan_novak@zoznam.sk"
          type="email"
          name="email"
          {...props}
        />
        <InputWithStartIcon
          iconName="password"
          iconType={IconType.gray}
          placeholder="•••••••••"
          type="password"
          name="password"
          {...props}
        />
        <Button
          buttonText="loginYourself"
          onClick={() => {
            loginHandler();
          }}
          disabled={!canSubmit}
          style={{ fontWeight: 400 }}
        />
      </div>
      <div className={styles["cant-login"]}>
        <p>
          {t("cantLoginText")}
          <a
            className={styles["cant-login-link"]}
            onClick={() => navigate(ASK_FOR_PASSWORD_RESET_PATH)}
          >
            {t("cantLoginLink")}
          </a>
        </p>
      </div>
    </Box>
  );
};
