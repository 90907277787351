import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRegisterUserMutation } from "../../../api/citizenApi";
import { Box } from "../../../components/Box";
import { Button } from "../../../components/Button";
import { IconType } from "../../../components/Icon";
import { InputWithStartIcon } from "../../../components/InputWithStartIcon";
import {
  useRegisterInputValidation,
  useValueObject,
  Icons,
  getErrorMessageFromPromise,
  getPreferredLanguage,
  languagesArray,
} from "../../../utils";
import styles from "./Register.module.scss";
import { LabeledInputContainer } from "../../../components/LabeledInputContainer";
import { RegisterRequest, ValueObject } from "../../../types";
import { PlatformStoreImage } from "../../PlatformStoreImage";
import { useSnackbar } from "notistack";
import { AuthBoxHeader } from "../../AuthBoxHeader";

const initialValueObject: ValueObject = {
  email: "",
  password: "",
  passwordAgain: "",
};

export const Register = () => {
  const { t } = useTranslation();
  const props = useValueObject(initialValueObject);
  const [registerUser] = useRegisterUserMutation();
  const inputErr = useRegisterInputValidation(props.valueObject);
  const [userRegisteredState, setUserRegisteredState] = useState(false);
  const { registrationToken } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const canSubmit =
    !inputErr.emailErr.error && !inputErr.passwordErr.error && !inputErr.passwordAgainErr.error;

  const registerHandler = () => {
    const registerRequest: RegisterRequest = {
      email: props.valueObject.email,
      password: props.valueObject.password,
      token: registrationToken ?? "",
      language: languagesArray.indexOf(getPreferredLanguage()),
    };
    registerUser(registerRequest)
      .unwrap()
      .then(() => setUserRegisteredState(true))
      .catch(error => {
        if (getErrorMessageFromPromise(error) === "jwt malformed") {
          enqueueSnackbar(t("notValidURL"), { variant: "error" });
        } else if (getErrorMessageFromPromise(error) === "Owner already registered") {
          enqueueSnackbar(t("userAlreadyExist"), { variant: "error" });
        }
      });
  };

  return (
    <>
      {userRegisteredState ? (
        <Box className={styles["register-successful-container"]}>
          <h1>{t("registerSuccessful")}</h1>
          <p>{t("downloadApp")}</p>
          <div className={styles["download-container"]}>
            <PlatformStoreImage />{" "}
          </div>
        </Box>
      ) : (
        <Box className={styles["register-container"]}>
          <AuthBoxHeader title={t("userRegistration")} />
          <LabeledInputContainer
            title={["email", "password", "passwordAgain"]}
            customClass="no-padding"
          >
            <InputWithStartIcon
              iconName={Icons.mail}
              iconType={IconType.gray}
              placeholder="jan_novak@zoznam.sk"
              type="email"
              name="email"
              {...props}
              {...inputErr.emailErr}
            />
            <InputWithStartIcon
              iconName={Icons.password}
              iconType={IconType.gray}
              placeholder="•••••••••"
              type="password"
              name="password"
              {...props}
              {...inputErr.passwordErr}
            />
            <InputWithStartIcon
              iconName={Icons.password}
              iconType={IconType.gray}
              placeholder="•••••••••"
              type="password"
              name="passwordAgain"
              {...props}
              {...inputErr.passwordAgainErr}
            />
          </LabeledInputContainer>
          <Button
            buttonText={t("register")}
            onClick={registerHandler}
            disabled={!canSubmit || registrationToken === undefined}
          />
        </Box>
      )}
    </>
  );
};
