export const getJwtFromLocalStorage = (): string | null => {
  return window.localStorage.getItem("jwt");
};

export const setJwtToLocalStorage = (jwt: string): void => {
  window.localStorage.setItem("jwt", jwt);
};

export const removeJwtFromLocalStorage = (): void => {
  window.localStorage.removeItem("jwt");
};
