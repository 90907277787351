import { isAnyOf } from "@reduxjs/toolkit";
import { citizenApi } from "../api/citizenApi";
import { defaultNotificationAheadTime, defaultNotificationTime } from "../types";
import { checkNotificationsPermission, getDeviceSpecificFcmEntry } from "../utils";
import { startAppListening } from "./listenerMiddleware";
import { getDeviceToken, getUser } from "../selectors";

export const apiListener = () => {
  startAppListening({
    matcher: isAnyOf(
      citizenApi.endpoints.loginUser.matchFulfilled,
      citizenApi.endpoints.whoami.matchFulfilled
    ),
    effect: async (_, { getState, dispatch }) => {
      const state = getState();
      const user = getUser(state);
      const fcmToken = getDeviceToken(state);

      if (user && fcmToken) {
        const fcmEntry = getDeviceSpecificFcmEntry(user.devices, fcmToken);

        if (fcmEntry) {
          // User already entered this device
          dispatch(citizenApi.endpoints.logInFcmEntry.initiate(fcmEntry.ownersDeviceId));
        } else {
          // User logged for the first time to this device
          checkNotificationsPermission(message => {
            dispatch(
              citizenApi.endpoints.createFcmTokenEntry.initiate({
                token: message.data && message.data === "granted" ? fcmToken : null, // GRANTED --> token is set to its value
                notificationTime: defaultNotificationTime,
                notificationAheadTime: defaultNotificationAheadTime,
                device: fcmToken,
                loggedIn: true,
              })
            );
          });
        }
      }
    },
  });

  startAppListening({
    matcher: citizenApi.endpoints.createFcmTokenEntry.matchRejected,
    effect: async (_, { dispatch }) => {
      dispatch(citizenApi.util.resetApiState());
    },
  });
};
