import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import store from "./store";
import { Provider } from "react-redux";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { DEFAULT_LANG, getPreferredLanguage } from "./utils";
import { StyledEngineProvider } from "@mui/styled-engine";

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/translations.json?v=${Date.now()}`,
    },
    load: "all",
    fallbackLng: DEFAULT_LANG,
    lng: getPreferredLanguage(),
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  });
