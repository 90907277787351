import { ApplicationState } from "./application";
import { UserState } from "./user";

export interface RootState {
  user: UserState;
  application: ApplicationState;
}

export interface RequestingProgress {
  status: Status;
  errorMessageKey?: string;
}

export enum Status {
  idle = "idle",
  requesting = "requesting",
  error = "error",
  success = "success",
}
