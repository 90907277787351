import React, { MouseEventHandler } from "react";
import { TextNature } from "../../types";
import { Icon, IconType } from "../Icon";
import styles from "./RowWithIconContainer.module.scss";
import SVG from "react-inlinesvg";

export interface RowContainerProps {
  iconName: string;
  iconType?: IconType;
  mainText: string;
  secondaryText?: string;
  path?: string;
  rightText?: string;
  rightTextNature?: TextNature;
  rightIconName?: string;
  rightIconType?: IconType;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<HTMLElement>;
  isSvg?: boolean;
  svgColorPrimary?: string;
  secondaryTextNature?: TextNature;
}

export const RowWithIconContainer: React.FC<RowContainerProps> = ({
  iconName,
  iconType = IconType.default,
  mainText,
  secondaryText,
  rightText,
  rightTextNature = TextNature.neutral,
  rightIconName,
  rightIconType = IconType.default,
  style,
  onClick,
  isSvg = false,
  svgColorPrimary,
  secondaryTextNature = TextNature.neutral,
}) => {
  const rightTextClasses = [styles["right-text"]];
  const mainTextClasses = [styles["main-text"]];
  const secondaryTextClasses = [styles["secondary-text"]];

  rightTextClasses.push(styles[`${rightTextNature.toString()}-text`]);
  secondaryTextClasses.push(styles[`${secondaryTextNature.toString()}-text`]);

  if (!secondaryText) {
    rightTextClasses.push(styles["no-secondary"]);
    mainTextClasses.push(styles["no-secondary"]);
  }

  return (
    <div className={styles.container} style={style} onClick={onClick}>
      <div className={styles.icon}>
        {isSvg ? (
          <SVG stroke={svgColorPrimary} fill={svgColorPrimary} src={`/icons/${iconName}.svg`} />
        ) : (
          <Icon style={{ width: "2.5rem", height: "auto" }} name={iconName} type={iconType} />
        )}
      </div>
      <h3 className={mainTextClasses.join(" ")}>{mainText}</h3>
      {secondaryText && <h4 className={secondaryTextClasses.join(" ")}>{secondaryText}</h4>}
      {rightText && <div className={rightTextClasses.join(" ")}>{rightText}</div>}
      {rightIconName && (
        <Icon
          style={{
            width: "1.5rem",
            height: "auto",
            gridArea: "1/3/3/3",
            justifySelf: "flex-end",
            alignSelf: "center",
          }}
          name={rightIconName}
          type={rightIconType}
        />
      )}
    </div>
  );
};
