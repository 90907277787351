import React, { CSSProperties } from "react";
import styles from "./Layout.module.scss";

interface LayoutProps {
  children: React.ReactNode;
  style?: CSSProperties;
  useWelcomeBackground?: boolean;
  topBar?: JSX.Element;
  navBar?: JSX.Element;
  fixedTop?: boolean;
  includeGaps?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  style,
  useWelcomeBackground = false,
  topBar,
  navBar,
  fixedTop = true,
  includeGaps = true,
}) => {
  const containerStyles = [styles.container];
  if (useWelcomeBackground) containerStyles.push(styles["welcome-bg-image"]);
  if (!fixedTop) containerStyles.push(styles["no-fixed-top"]);
  const innerContainerStyles = [styles["inner-container"]];
  if (fixedTop) innerContainerStyles.push(styles["fixed-top"]);

  return (
    <div className={containerStyles.join(" ")} style={style}>
      {fixedTop && <div className={styles["top-bar"]}>{topBar}</div>}
      <div className={styles["main-content"]}>
        <div
          className={
            includeGaps ? innerContainerStyles.join(" ") : styles["container-without-padding"]
          }
        >
          {!fixedTop && topBar}
          {children}
        </div>
      </div>
      {navBar}
    </div>
  );
};
