import { useTranslation } from "react-i18next";
import styles from "./NoChartData.module.scss";

export const NoChartData = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <p>{t("noChartSeparationData")}</p>
    </div>
  );
};
