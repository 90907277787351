import { useTranslation } from "react-i18next";
import { useGetWasteCollectionCalendarQuery } from "../../../api/citizenApi";
import { BoxWithDividerLines, IterableItem } from "../../BoxWithDividerLines";
import { CalendarDay } from "../../CalendarDay";
import { NoData } from "../../NoData";
import { getCalendarEventsBody } from "./getCalendarEventsBody";

interface WagonsCalendarProps {
  maxEventsCount?: number;
}

export const WagonsCalendar: React.FC<WagonsCalendarProps> = ({ maxEventsCount }) => {
  const { t } = useTranslation();
  const { data } = useGetWasteCollectionCalendarQuery(getCalendarEventsBody());

  if (data && data.length > 0) {
    const fetchedEvents = maxEventsCount ? data.slice(0, maxEventsCount) : data;
    const calendarBoxItems: IterableItem[] = fetchedEvents.map((calendarItem, index) => ({
      id: index,
      element: <CalendarDay event={calendarItem} />,
    }));

    return <BoxWithDividerLines items={calendarBoxItems} />;
  }
  return <NoData text={t("noFutureWagonEvents")} />;
};
