import { FcmEntry } from "../types";

export const getDeviceSpecificFcmEntry = (
  fcmEntries: FcmEntry[],
  deviceToken: string
): FcmEntry | null => {
  const filtered = fcmEntries.filter(entry => deviceToken === entry.device);
  if (filtered.length === 0) {
    return null;
  }
  return filtered[0];
};
