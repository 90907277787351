import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Icon, IconType } from "../Icon";

import styles from "./NavigationTitle.module.scss";

export enum NavigationBackground {
  neutral = "neutral",
  inherit = "inherit",
}

interface NavigationTitleProps {
  title: string;
  iconName?: string;
  iconType?: IconType;
  style?: CSSProperties;
  background?: NavigationBackground;
  includeDivider?: boolean;
}

export const NavigationTitle: React.FC<NavigationTitleProps> = ({
  title,
  iconName = "arrowLeft",
  iconType = IconType.default,
  style,
  background = NavigationBackground.neutral,
  includeDivider = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const containerClasses = [styles.container, styles[background]];
  if (includeDivider) containerClasses.push(styles.includeDivider);

  return (
    <div className={containerClasses.join(" ")} style={style}>
      <Icon name={iconName} type={iconType} onClick={goBack} />
      <h3 className={styles.title}>{t(title)}</h3>
    </div>
  );
};
