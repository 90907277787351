import { useTranslation } from "react-i18next";
import { GeneralTitle } from "../../components/GeneralTitle";
import { Layout } from "../../components/Layout";
import { Navbar } from "../../components/Navbar";
import { Recycling } from "../../components/PagesContent/Recycling";
import { YearSelect } from "../../components/SelectComponent";

export const RecyclingOverviewPage = () => {
  const { t } = useTranslation();

  const topBar = (
    <GeneralTitle mainText={t("separationPageTitle")} rightComponent={<YearSelect />} />
  );
  const navBar = <Navbar />;

  return (
    <Layout topBar={topBar} navBar={navBar}>
      <Recycling />
    </Layout>
  );
};
