import { Icon, IconType } from "../../components/Icon";
import styles from "./WelcomeScreen.module.scss";
import { openBrowserHandler, Icons } from "../../utils";
import { CSSProperties } from "react";

type WelcomeScreenProps = {
  children: React.ReactNode;
  style?: CSSProperties;
};

export const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ children, style }) => {
  return (
    <div className={styles.container} style={style}>
      <div className={styles["icon-container"]}>
        <Icon name={Icons.ewaLogo} type={IconType.default} />
      </div>
      <div className={styles.inner}>
        <div className={styles.slider}>{children}</div>
      </div>
      <div className={styles["koala-logo"]}>
        <span onClick={() => openBrowserHandler("https://koala42.com")}>
          <p>Developed by</p>
          <Icon name={Icons.logoKoala} type={IconType.default} />
        </span>
      </div>
    </div>
  );
};
