import React, { CSSProperties, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { doNothing } from "../../utils";
import styles from "./Button.module.scss";

export enum ButtonVariant {
  primary = "primary",
}

interface ButtonProps {
  variant?: ButtonVariant;
  buttonText: string;
  onClick?: MouseEventHandler;
  style?: CSSProperties;
  customCssClass?: string;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  variant = ButtonVariant.primary,
  buttonText,
  onClick,
  style,
  customCssClass = "",
  disabled = false,
}) => {
  const buttonClasses = [styles.button];
  if (customCssClass) {
    buttonClasses.push(styles[customCssClass]);
  }

  const { t } = useTranslation();

  if (variant === ButtonVariant.primary) buttonClasses.push(styles.primary);

  return (
    <button
      onClick={disabled ? doNothing : onClick}
      className={buttonClasses.join(" ")}
      style={style}
      disabled={disabled}
    >
      {t(buttonText)}
    </button>
  );
};
