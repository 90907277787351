import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ValueObject } from "../../types";
import { Icon, IconType } from "../Icon";

interface InputWithStartIconProps {
  placeholder?: string;
  type?: string;
  name: keyof ValueObject & string;
  iconName: string;
  iconType: IconType;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  valueObject: ValueObject;
  error?: boolean;
  errorMessage?: string;
}

export const InputWithStartIcon: React.FC<InputWithStartIconProps> = ({
  placeholder = "",
  type = "text",
  name,
  iconName,
  iconType,
  handleChange,
  valueObject,
  error,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      label=""
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon name={iconName} type={iconType} />
          </InputAdornment>
        ),
        placeholder: t(placeholder),
        type,
        name,
      }}
      value={valueObject ? valueObject[name] : ""}
      onChange={handleChange}
      error={error}
      helperText={errorMessage}
      color="info"
    />
  );
};
