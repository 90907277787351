import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./LabeledInputContainer.module.scss";

interface LabeledInputContainerProps {
  title: string | string[];
  children: React.ReactNode;
  customClass?: string;
}

export const LabeledInputContainer: React.FC<LabeledInputContainerProps> = ({
  title,
  children,
  customClass = "",
}) => {
  const { t } = useTranslation();

  const appliedClassesArray = [styles.container];
  if (customClass) appliedClassesArray.push(styles[customClass]);

  if (title instanceof Array) {
    return (
      <div className={appliedClassesArray.join(" ")}>
        {title.map((titleItem, index) => (
          <React.Fragment key={index}>
            <h5 className={styles.title}>{t(titleItem)}</h5>
            {React.Children.toArray(children)[index]}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <div className={appliedClassesArray.join(" ")}>
      <h5 className={styles.title}>{t(title)}</h5>
      {children}
    </div>
  );
};
