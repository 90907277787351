import React from "react";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { getApplicationMode, getUserStatus } from "../../selectors";
import { ThemeProvider } from "@mui/material";
import { Status } from "../../types";
import { ContentLoader } from "../ContentLoader";
import "./App.scss";
import { useGetRefreshedTokenQuery, useWhoamiQuery } from "../../api/citizenApi";
import { NoInternetConnection } from "../NoInternetConnection";
import { themeHandler, useAdjustSafariLayout } from "../../utils";
import { SnackbarProvider } from "notistack";
import { SnackbarComponent } from "../SnackbarComponent";
import { appRouter } from "./appRouter";
import { useRetrieveFcmToken } from "./useRetrieveFcmToken";
import { usePreloadAssets, getIconsToPreload } from "../../utils/preloadAssetsHook";

export const App: React.FC<{}> = () => {
  const userStatus = useSelector(getUserStatus);
  const applicationMode = useSelector(getApplicationMode);
  // const isBigScreen = useResize(768);
  useRetrieveFcmToken();
  useAdjustSafariLayout();
  useWhoamiQuery(null);
  useGetRefreshedTokenQuery(null);
  const assetsLoading = usePreloadAssets(getIconsToPreload());

  return (
    <ThemeProvider theme={themeHandler(applicationMode)}>
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={1}
        Components={{
          success: SnackbarComponent,
          error: SnackbarComponent,
          info: SnackbarComponent,
        }}
      >
        <NoInternetConnection>
          {userStatus === Status.requesting || assetsLoading ? (
            <ContentLoader />
          ) : (
            <RouterProvider router={appRouter} fallbackElement={<p>Loading...</p>} />
          )}
        </NoInternetConnection>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
