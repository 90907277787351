import { IconType } from "../../Icon";
import { InputWithStartIcon } from "../../InputWithStartIcon";
import { LabeledInputContainer } from "../../LabeledInputContainer";

import { Button } from "../../Button";
import {
  useValueObject,
  useValidatePasswordChange,
  getErrorMessageFromPromise,
  removeJwtFromLocalStorage,
  setJwtToLocalStorage,
} from "../../../utils";
import { useChangePasswordMutation } from "../../../api/citizenApi";
import styles from "./ChangePassword.module.scss";
import { Divider } from "@mui/material";
import { ValueObject } from "../../../types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const initialValueObject: ValueObject = {
  currentPassword: "",
  password: "",
  passwordAgain: "",
};

export const ChangePassword = () => {
  const props = useValueObject(initialValueObject);
  const inputErr = useValidatePasswordChange(props.valueObject);
  const [changePassword] = useChangePasswordMutation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const canSubmit =
    !inputErr.currentPasswordErr.error &&
    !inputErr.passwordErr.error &&
    !inputErr.passwordAgainErr.error;

  const changePasswordHandler = () => {
    const passwords = {
      password: props.valueObject.currentPassword,
      newPassword: props.valueObject.password,
    };
    changePassword(passwords)
      .unwrap()
      .then(response => {
        enqueueSnackbar(t("changePasswordSuccess"), { variant: "success" });
        removeJwtFromLocalStorage();
        setJwtToLocalStorage(response.token);
        navigate(-1);
      })
      .catch(error => {
        if (getErrorMessageFromPromise(error) === "Not Found")
          enqueueSnackbar(t("wrongPassword"), { variant: "error" });
      });
  };

  return (
    <div className={styles["change-password-container"]}>
      <Divider />
      <LabeledInputContainer
        title={["currentPassword", "newPassword", "passwordAgain"]}
        customClass="full-height"
      >
        <InputWithStartIcon
          iconName="password"
          iconType={IconType.gray}
          placeholder="•••••••••"
          type="password"
          name="currentPassword"
          {...props}
          {...inputErr.currentPasswordErr}
        />
        <InputWithStartIcon
          iconName="password"
          iconType={IconType.gray}
          placeholder="•••••••••"
          type="password"
          name="password"
          {...props}
          {...inputErr.passwordErr}
        />
        <InputWithStartIcon
          iconName="password"
          iconType={IconType.gray}
          placeholder="•••••••••"
          type="password"
          name="passwordAgain"
          {...props}
          {...inputErr.passwordAgainErr}
        />
      </LabeledInputContainer>
      <Divider />
      <div className={styles["button-container"]}>
        <Button buttonText="save" onClick={changePasswordHandler} disabled={!canSubmit} />
      </div>
      <Divider />
    </div>
  );
};
