import { Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetWasteCollectionCalendarQuery,
  useUpdateFcmTokenEntryMutation,
} from "../../../api/citizenApi";
import { getCurrentDeviceFcmEntry, getDeviceToken } from "../../../selectors";
import {
  defaultNotificationAheadTime,
  defaultNotificationTime,
  UpdateFcmRequest,
} from "../../../types";
import {
  checkNotificationsPermission,
  navigateToApplicationSettings,
  answersOptionsArray,
  notificationDayOptionsArray,
  Asnwer,
  timesOptionsArray,
  formattedDate,
} from "../../../utils";
import { Button } from "../../Button";
import { DialogComponent } from "../../DialogComponent";
import { InfoBox } from "../../InfoBox";
import { LabeledInputContainer } from "../../LabeledInputContainer";
import { SelectComponent } from "../../SelectComponent";
import { getCalendarEventsBody } from "../WagonsCalendar";
import styles from "./NotificationSettings.module.scss";

export const NotificationSettings = () => {
  const { t } = useTranslation();
  const fcmToken = useSelector(getDeviceToken);
  const fcmEntry = useSelector(getCurrentDeviceFcmEntry);
  const [open, setOpen] = useState(false);
  const [updateFcmTokenEntry] = useUpdateFcmTokenEntryMutation();
  const { data: calendarEvents } = useGetWasteCollectionCalendarQuery(getCalendarEventsBody());
  const [wantsReceiveNotifications, setWantsReceiveNotifications] = useState(
    fcmEntry?.token ? true : false
  );
  const [notificationTime, setNotificationTime] = useState(
    fcmEntry?.notificationTime ?? defaultNotificationTime
  );
  const [notificationAheadTime, setNotificationAheadTime] = useState(
    fcmEntry?.notificationAheadTime ?? defaultNotificationAheadTime
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const saveSettingsHandler = () => {
    if (!fcmEntry) {
      enqueueSnackbar(t("snackbarMessages.unknownError"), {
        variant: "error",
      });
      return;
    }
    checkNotificationsPermission(checkPermissionMessage => {
      // NOT GRANTED
      if (checkPermissionMessage.data === "not granted" && wantsReceiveNotifications) {
        setOpen(true);
        // GRANTED
      } else {
        if (wantsReceiveNotifications) {
          handleUpdateFcmEntry({
            entryId: fcmEntry.ownersDeviceId,
            token: fcmToken,
            notificationTime,
            notificationAheadTime,
          });
        } else {
          handleUpdateFcmEntry({ entryId: fcmEntry.ownersDeviceId, token: null });
        }
      }
    });
  };

  const handleUpdateFcmEntry = (body: UpdateFcmRequest) => {
    updateFcmTokenEntry(body)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t("snackbarMessages.notificationChangeSuccess"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("snackbarMessages.actionFailure"), { variant: "error" });
      });
  };

  const handleSubmitDialog = () => {
    navigateToApplicationSettings();
    setOpen(false);
  };

  return (
    <div className={styles["notification-settings-container"]}>
      <Divider />
      {calendarEvents && calendarEvents.length > 0 && (
        <InfoBox
          text={`${t("theClosestWagonEventInfoText")} ${formattedDate(
            new Date(calendarEvents[0].date)
          )}.`}
        />
      )}
      <LabeledInputContainer
        title={
          wantsReceiveNotifications
            ? ["doWantNotifications", "day", "time"]
            : ["doWantNotifications"]
        }
        customClass="full-height"
      >
        <SelectComponent
          customCssClasses={["full-width"]}
          value={wantsReceiveNotifications ? Asnwer.yes : Asnwer.no}
          optionsArray={answersOptionsArray}
          onChange={event => {
            setWantsReceiveNotifications(event.target.value === Asnwer.yes ? true : false);
          }}
        />
        <SelectComponent
          customCssClasses={["full-width"]}
          value={notificationAheadTime}
          optionsArray={notificationDayOptionsArray}
          onChange={event => setNotificationAheadTime(event.target.value)}
        />
        <SelectComponent
          customCssClasses={["full-width"]}
          value={notificationTime}
          optionsArray={timesOptionsArray}
          onChange={event => setNotificationTime(event.target.value)}
          translate={false}
        />
      </LabeledInputContainer>
      <Divider />
      <div className={styles["button-container"]}>
        <Button buttonText="save" onClick={saveSettingsHandler} />
        <DialogComponent
          onClose={handleClose}
          open={open}
          text="allowNotificationPrompt"
          successButtonText="goToSettings"
          onSubmitDialog={handleSubmitDialog}
        />
      </div>
      <Divider />
    </div>
  );
};
