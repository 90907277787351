import React from "react";

import styles from "./ContentLoader.module.scss";

export const ContentLoader: React.FC<{}> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container__spinner} />
    </div>
  );
};
