import { ISelectOption } from "../types";
import {
  Asnwer,
  Month,
  WagonNotificationDay,
  WagonNotificationDayValue,
  WasteTypes,
} from "./enums";

export const wasteTypesOptionsArray: ISelectOption[] = [
  {
    value: WasteTypes.mixed,
    label: `enums.wasteTypes.${WasteTypes.mixed}`,
  },
  {
    value: WasteTypes.paper,
    label: `enums.wasteTypes.${WasteTypes.paper}`,
  },
  {
    value: WasteTypes.glass,
    label: `enums.wasteTypes.${WasteTypes.glass}`,
  },
  {
    value: WasteTypes.plastics,
    label: `enums.wasteTypes.${WasteTypes.plastics}`,
  },
];

export const monthsOptionsArray: ISelectOption[] = [
  {
    value: 1,
    label: `enums.months.${Month.january}`,
  },
  {
    value: 2,
    label: `enums.months.${Month.february}`,
  },
  {
    value: 3,
    label: `enums.months.${Month.march}`,
  },
  {
    value: 4,
    label: `enums.months.${Month.april}`,
  },
  {
    value: 5,
    label: `enums.months.${Month.may}`,
  },
  {
    value: 6,
    label: `enums.months.${Month.june}`,
  },
  {
    value: 7,
    label: `enums.months.${Month.july}`,
  },
  {
    value: 8,
    label: `enums.months.${Month.august}`,
  },
  {
    value: 9,
    label: `enums.months.${Month.september}`,
  },
  {
    value: 10,
    label: `enums.months.${Month.october}`,
  },
  {
    value: 11,
    label: `enums.months.${Month.november}`,
  },
  {
    value: 12,
    label: `enums.months.${Month.december}`,
  },
];

export const answersOptionsArray: ISelectOption[] = [
  {
    value: Asnwer.yes,
    label: `enums.answers.${Asnwer.yes}`,
  },
  {
    value: Asnwer.no,
    label: `enums.answers.${Asnwer.no}`,
  },
];

export const notificationDayOptionsArray: ISelectOption[] = [
  {
    value: WagonNotificationDayValue.twoDaysBeforeEvent,
    label: `enums.wagonNotificationDay.${WagonNotificationDay.twoDaysBeforeEvent}`,
  },
  {
    value: WagonNotificationDayValue.oneDayBeforeEvent,
    label: `enums.wagonNotificationDay.${WagonNotificationDay.oneDayBeforeEvent}`,
  },
  {
    value: WagonNotificationDayValue.onEvent,
    label: `enums.wagonNotificationDay.${WagonNotificationDay.onEvent}`,
  },
];

// years options
const lastYears: ISelectOption[] = [];

let currentYear = new Date().getFullYear();

while (lastYears.length < 4) {
  lastYears.push({ value: Number(currentYear), label: String(currentYear) });
  currentYear--;
}

export { lastYears };

// time options
const timesOptionsArray: ISelectOption[] = [];

for (let hour = 0; hour < 24; hour++) {
  timesOptionsArray.push({
    value: hour,
    label: `${String(hour).padStart(2, "0")}:00`,
  });
}

export { timesOptionsArray };
