const android = (window as any).JSBridge;
const iOS = (window as any).webkit;
(window as any).callbacks = (window as any).callbacks ? (window as any).callbacks : {};

(window as any).callbacks = {
  onCheckNotificationsPermission: (window as any).callbacks.onCheckNotificationsPermission || null,
  onGetNotificationsPermissionAskedStatus:
    (window as any).callbacks.onGetNotificationsPermissionAskedStatus || null,
  onGetFcmToken: (window as any).callbacks.onGetFcmToken || null,
};

const callbacks = (window as any).callbacks;

const bridgeMessagesHandler = (message: any) => {
  if (message.messageType === "onCheckNotificationsPermission") {
    if (callbacks.onCheckNotificationsPermission) callbacks.onCheckNotificationsPermission(message);
  } else if (message.messageType === "onGetNotificationsPermissionAskedStatus") {
    if (callbacks.onGetNotificationsPermissionAskedStatus)
      callbacks.onGetNotificationsPermissionAskedStatus(message);
  } else if (message.messageType === "onGetFcmToken") {
    if (callbacks.onGetFcmToken) callbacks.onGetFcmToken(message);
  }
};

export const openBrowserHandler = (url: string): void => {
  if (!iOS && android) {
    android.postMessage(JSON.stringify({ action: "openBrowser", url }));
    return;
  } else if (iOS?.messageHandlers?.ReactObserver?.postMessage) {
    iOS.messageHandlers.ReactObserver.postMessage({
      action: "openBrowser",
      url,
    });
  } else {
    window.open(url);
  }
};

export const getNotificationsPermissionAskedStatus = (callback: (data: any) => void): void => {
  if (!iOS && android) {
    android.postMessage(JSON.stringify({ action: "getNotificationsPermissionAskedStatus" }));
  } else if (iOS?.messageHandlers?.ReactObserver?.postMessage) {
    iOS.messageHandlers.ReactObserver.postMessage({
      action: "getNotificationsPermissionAskedStatus",
    });
  }

  callbacks.onGetNotificationsPermissionAskedStatus = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const getFcmToken = (callback: (data: any) => void): void => {
  if (!iOS && android) {
    android.postMessage(JSON.stringify({ action: "getFcmToken" }));
  } else if (iOS?.messageHandlers?.ReactObserver?.postMessage) {
    iOS.messageHandlers.ReactObserver.postMessage({
      action: "getFcmToken",
    });
  }

  callbacks.onGetFcmToken = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const navigateToApplicationSettings = (): void => {
  if (!iOS && android) {
    android.postMessage(JSON.stringify({ action: "navigateToApplicationSettings" }));
  } else if (iOS?.messageHandlers?.ReactObserver?.postMessage) {
    iOS.messageHandlers.ReactObserver.postMessage({
      action: "navigateToApplicationSettings",
    });
  }
};

export const checkNotificationsPermission = (callback: (data: any) => void): void => {
  if (!iOS && android) {
    android.postMessage(JSON.stringify({ action: "checkNotificationsPermission" }));
  } else if (iOS?.messageHandlers?.ReactObserver?.postMessage) {
    iOS.messageHandlers.ReactObserver.postMessage({
      action: "checkNotificationsPermission",
    });
  }

  callbacks.onCheckNotificationsPermission = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const changeDisplayModeHandler = (
  displayMode: string = "",
  topColor: string = "",
  bottomColor: string = ""
) => {
  if (!iOS) return;

  iOS.messageHandlers.ReactObserver.postMessage({
    action: "changeDisplayMode",
    displayMode,
    topColor,
    bottomColor,
  });
};

export const androidGoFullscreen = () => {
  if (!iOS && android) {
    android.postMessage(JSON.stringify({ action: "goFullScreen" }));
  }
};

export const androidExitFullscreen = () => {
  if (!iOS && android) {
    android.postMessage(JSON.stringify({ action: "exitFullScreen" }));
  }
};

export const logEvent = (name: string, params: object) => {
  if (!name) {
    return;
  }

  if (android) {
    // Call Android interface
    android.logEvent(name, JSON.stringify(params));
  } else if (iOS && iOS.messageHandlers && iOS.messageHandlers.firebase) {
    // Call iOS interface
    const message = {
      command: "logEvent",
      name,
      parameters: params,
    };
    iOS.messageHandlers.firebase.postMessage(message);
  } else {
    // No Android or iOS interface found
    // console.info("No native APIs found.");
    return;
  }
};

export const setUserProperty = (name: string, value: string) => {
  if (!name || !value) {
    return;
  }

  if (android) {
    // Call Android interface
    android.setUserProperty(name, value);
  } else if (iOS && iOS.messageHandlers && iOS.messageHandlers.firebase) {
    // Call iOS interface
    const message = {
      command: "setUserProperty",
      name,
      value,
    };
    iOS.messageHandlers.firebase.postMessage(message);
  } else {
    // No Android or iOS interface found
    // console.log("No native APIs found.");
    return;
  }
};
