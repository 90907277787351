import { Outlet } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { WelcomeScreen } from "../../components/WelcomeScreen";

export const AuthPage = () => {
  const loginPageStyle = {
    width: "100vw",
    height: "100vh",
  };

  return (
    <Layout includeGaps={false} useWelcomeBackground={true} style={loginPageStyle} fixedTop={false}>
      <WelcomeScreen>
        <Outlet />
      </WelcomeScreen>
    </Layout>
  );
};
