import { useState, useEffect } from "react";

export const useResize = (breakpoint: number): boolean => {
  const [matches, setMatches] = useState(window.matchMedia(`(min-width: ${breakpoint}px)`).matches);

  useEffect(() => {
    const setMatchesCallback = (e: MediaQueryListEvent) => setMatches(e.matches);
    window
      .matchMedia(`(min-width: ${breakpoint}px)`)
      .addEventListener("change", setMatchesCallback);

    return () => {
      window
        .matchMedia(`(min-width: ${breakpoint}px)`)
        .removeEventListener("change", setMatchesCallback);
    };
  }, [breakpoint]);

  return matches;
};
