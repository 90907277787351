import { createBrowserRouter } from "react-router-dom";
import { PrivateRoute } from "../PrivateRoute";
import "./App.scss";
import { HomePage } from "../../containers/HomePage";
import { RootPage } from "../../containers/RootPage";
import { ProfileOverviewPage } from "../../containers/ProfileOverviewPage";
import { ChangePasswordPage } from "../../containers/ChangePasswordPage";
import { RecyclingOverviewPage } from "../../containers/RecyclingOverviewPage";
import { WeightHistoryDetailPage } from "../../containers/WeightHistoryDetailPage";
import { ChangeEmailPage } from "../../containers/ChangeEmailPage";
import { LandingPage } from "../../containers/LandingPage";
import { ErrorPage } from "../../containers/ErrorPage";
import { WagonsCalendarPage } from "../../containers/WagonsCalendarPage";
import { ChangeLanguagePage } from "../../containers/ChangeLanguagePage";
import { NotificationSettingsPage } from "../../containers/NotificationSettings";
import {
  ASK_FOR_PASSWORD_RESET_PATH,
  AUTH_PATH,
  CALENDAR_PATH,
  CHANGE_EMAIL_PATH,
  CHANGE_LANGUAGE_PATH,
  CHANGE_PASSWORD_PATH,
  HOME_PATH,
  HOW_TO_REGISTER_PATH,
  INDEX_PATH,
  LOGIN_PATH,
  NOTIFICATION_SETTINGS_PATH,
  PROFILE_PATH,
  RECYCLING_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  WEIGHT_HISTORY_PATH,
} from "../../constants";
import { Login } from "../PagesContent/Login";
import { ResetPassword } from "../PagesContent/ResetPassword";
import { AskForPasswordReset } from "../PagesContent/AskForPasswordReset";
import { HowToRegister } from "../PagesContent/HowToRegister";
import { AuthPage } from "../../containers/AuthPage";
import { Register } from "../PagesContent/Register";

export const appRouter = createBrowserRouter([
  {
    path: INDEX_PATH,
    element: <RootPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        path: "*",
        element: <LandingPage />,
      },
      {
        path: AUTH_PATH,
        element: <AuthPage />,
        children: [
          {
            path: `${REGISTER_PATH}/:registrationToken`,
            element: <Register />,
          },
          {
            path: LOGIN_PATH,
            element: <Login />,
          },
          {
            path: `${RESET_PASSWORD_PATH}/:resetPasswordToken`,
            element: <ResetPassword />,
          },
          {
            path: ASK_FOR_PASSWORD_RESET_PATH,
            element: <AskForPasswordReset />,
          },
          {
            path: HOW_TO_REGISTER_PATH,
            element: <HowToRegister />,
          },
        ],
      },
      {
        path: HOME_PATH,
        element: (
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE_PATH,
        element: (
          <PrivateRoute>
            <ProfileOverviewPage />
          </PrivateRoute>
        ),
      },
      {
        path: CHANGE_PASSWORD_PATH,
        element: (
          <PrivateRoute>
            <ChangePasswordPage />
          </PrivateRoute>
        ),
      },
      {
        path: NOTIFICATION_SETTINGS_PATH,
        element: (
          <PrivateRoute>
            <NotificationSettingsPage />
          </PrivateRoute>
        ),
      },
      {
        path: CHANGE_LANGUAGE_PATH,
        element: (
          <PrivateRoute>
            <ChangeLanguagePage />
          </PrivateRoute>
        ),
      },
      {
        path: CHANGE_EMAIL_PATH,
        element: (
          <PrivateRoute>
            <ChangeEmailPage />
          </PrivateRoute>
        ),
      },
      {
        path: RECYCLING_PATH,
        element: (
          <PrivateRoute>
            <RecyclingOverviewPage />
          </PrivateRoute>
        ),
      },
      {
        path: `${WEIGHT_HISTORY_PATH}/:containerId`,
        element: (
          <PrivateRoute>
            <WeightHistoryDetailPage />
          </PrivateRoute>
        ),
      },
      {
        path: CALENDAR_PATH,
        element: (
          <PrivateRoute>
            <WagonsCalendarPage />
          </PrivateRoute>
        ),
      },
    ],
  },
]);
