import React from "react";
import styles from "./GeneralTitle.module.scss";

interface GeneralTitleProps {
  mainText: string;
  secondaryText?: string;
  rightComponent: JSX.Element;
}
export const GeneralTitle: React.FC<GeneralTitleProps> = ({
  mainText,
  secondaryText,
  rightComponent,
}) => {
  return (
    <div className={secondaryText ? styles.container : styles.containerNoSecondary}>
      <h1 className={secondaryText ? styles.main : styles.mainNoSecondary}>{mainText}</h1>
      {secondaryText && <h2 className={styles.secondary}>{secondaryText}</h2>}

      <div className={secondaryText ? styles.right : styles.rightNoSecondary}>{rightComponent}</div>
    </div>
  );
};
