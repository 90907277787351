import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { LOGIN_PATH } from "../../constants";
import { getUser } from "../../selectors";

export const PrivateRoute: React.FC<{}> = ({ children }) => {
  const user = useSelector(getUser);
  return user ? <>{children}</> : <Navigate to={LOGIN_PATH} />;
};
