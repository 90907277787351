import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useAskForPasswordResetMutation } from "../../../api/citizenApi";
import { Box } from "../../../components/Box";
import { Button } from "../../../components/Button";
import { IconType } from "../../../components/Icon";
import { InputWithStartIcon } from "../../../components/InputWithStartIcon";
import { LOGIN_PATH } from "../../../constants";
import { ValueObject } from "../../../types";
import {
  getErrorMessageFromPromise,
  useAskForPasswordResetValidation,
  useValueObject,
} from "../../../utils";
import { AuthBoxHeader } from "../../AuthBoxHeader";
import styles from "./AskForPasswordReset.module.scss";

const initialValueObject: ValueObject = {
  email: "",
};

export const AskForPasswordReset = () => {
  const { t } = useTranslation();
  const [askForPasswordReset] = useAskForPasswordResetMutation();
  const props = useValueObject(initialValueObject);
  const inputErr = useAskForPasswordResetValidation(props.valueObject);
  const { enqueueSnackbar } = useSnackbar();

  const canSubmit = props.valueObject.email.length > 0 && !inputErr.emailErr.error;

  const askForPasswordResetHandler = () => {
    askForPasswordReset(props.valueObject.email)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t("snackbarMessages.emailSent"), { variant: "success" });
      })
      .catch(error => {
        if (getErrorMessageFromPromise(error) === "Owner not found") {
          enqueueSnackbar(t("snackbarMessages.unknownEmail"), { variant: "error" });
        } else if (getErrorMessageFromPromise(error) === "Previous token is still valid") {
          enqueueSnackbar(t("snackbarMessages.oldLinkStillValid"), { variant: "error" });
        }
      });
  };

  return (
    <Box className={styles.askForResetContainer}>
      <AuthBoxHeader
        title={t("resetPasswordTitle")}
        linkText={t("backToLogin")}
        linkTo={LOGIN_PATH}
      />
      <p>{t("resetPasswordText")}</p>
      <InputWithStartIcon
        iconName="mail"
        iconType={IconType.gray}
        placeholder="jan_novak@zoznam.sk"
        type="email"
        name="email"
        {...props}
        {...inputErr.emailErr}
      />
      <Button
        buttonText={t("resetPassword")}
        onClick={askForPasswordResetHandler}
        disabled={!canSubmit}
      />
    </Box>
  );
};
