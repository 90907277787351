interface ImageProps {
  imageName: string;
  width?: string;
  height?: string;
  className?: string;
  extension?: string;
  generateDensityDescriptors?: boolean;
}

export const Image = ({
  imageName,
  width,
  height,
  className,
  extension = "png",
  generateDensityDescriptors = false,
}: ImageProps) => {
  const srcSet = generateDensityDescriptors
    ? {
        srcSet: `/img/${imageName}.${extension} 1x, /img/${imageName}@2x.${extension} 2x, /img/${imageName}@3x.${extension} 3x`,
      }
    : {};

  return (
    <img
      {...{
        src: `/img/${imageName}.${extension}`,
        alt: imageName,
        width,
        height,
        className,
      }}
      {...srcSet}
    />
  );
};
