import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";

type DialogComponentProps = {
  title?: string;
  text: string;
  successButtonText: string;
  includeCancelOption?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmitDialog?: () => void;
};

export const DialogComponent: React.FC<DialogComponentProps> = ({
  title,
  text,
  includeCancelOption,
  successButtonText,
  open,
  onClose,
  onSubmitDialog,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{t(title)}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{t(text)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {includeCancelOption && (
          <Button onClick={onClose} buttonText={t("cancel")} customCssClass="max-content" />
        )}
        <Button
          onClick={onSubmitDialog ? onSubmitDialog : onClose}
          buttonText={t(successButtonText)}
          customCssClass="max-content"
        />
      </DialogActions>
    </Dialog>
  );
};
