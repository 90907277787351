import { Outlet } from "react-router-dom";
import { useSetDisplayMode } from "../../utils";

export const RootPage = () => {
  // location related hooks
  useSetDisplayMode();

  return (
    <>
      <Outlet />
    </>
  );
};
