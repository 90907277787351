import React from "react";
import styles from "./WeightHistoryDetail.module.scss";
import { BoxWithDividerLines } from "../../BoxWithDividerLines";
import { formattedDate } from "../../../utils";
import { CollectionEvent } from "../../../types";
import { useTranslation } from "react-i18next";

type WeightHistoryDetailProps = {
  collections: CollectionEvent[];
};

export const WeightHistoryDetail: React.FC<WeightHistoryDetailProps> = ({ collections }) => {
  const { t } = useTranslation();

  const collectionBoxItems = collections.map(({ weight, date }, index) => ({
    id: index,
    element: (
      <div className={styles.item}>
        <div className={styles.date}>{formattedDate(new Date(date))}</div>
        <div className={styles.weight}>{weight} kg</div>
      </div>
    ),
  }));

  if (collections.length === 0) {
    return <div>{t("noData")}</div>;
  }

  return <BoxWithDividerLines style={{ padding: "0.55rem" }} items={collectionBoxItems} />;
};
