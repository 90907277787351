import { useTranslation } from "react-i18next";
import { BoxWithDividerLines } from "../BoxWithDividerLines";
import { CollectedWasteGraphProps } from "../SummaryDoughnutChart";
import { VerticalBar } from "../VerticalBar";

import styles from "./CollectedWasteOverview.module.scss";

export const CollectedWasteOverview: React.FC<CollectedWasteGraphProps> = ({ data }) => {
  const { t } = useTranslation();

  const graphStatsBoxItems = data.map(({ type, perc, sum, color }, index) => ({
    id: index,
    element: (
      <div className={styles.item}>
        <div className={styles.bar}>
          <VerticalBar color={color} />
        </div>
        <div className={styles.type}>{t(`enums.wasteTypes.${type}`)}</div>
        <div className={styles.percantage}>{Math.round(perc)} %</div>
        <div className={styles.weight}>{sum} kg</div>
      </div>
    ),
  }));

  return <BoxWithDividerLines style={{ padding: "0.2rem" }} items={graphStatsBoxItems} />;
};
