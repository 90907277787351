import { IconType } from "../../Icon";
import { InputWithStartIcon } from "../../InputWithStartIcon";
import { LabeledInputContainer } from "../../LabeledInputContainer";
import { ValueObject } from "../../../types";
import { useValueObject } from "../../../utils";
import { Button } from "../../Button";
import styles from "./ChangeEmail.module.scss";

const initialValueObject: ValueObject = {
  currentEmail: "",
  email: "",
  password: "",
};

export const ChangeEmail = () => {
  const props = useValueObject(initialValueObject);

  return (
    <div className={styles["change-email-container"]}>
      <LabeledInputContainer
        title={["currentEmail", "newEmail", "password"]}
        customClass="full-height"
      >
        <InputWithStartIcon
          iconName="mail"
          iconType={IconType.gray}
          placeholder="jan_novak@email.sk"
          name="currentEmail"
          {...props}
        />
        <InputWithStartIcon
          iconName="mail"
          iconType={IconType.gray}
          placeholder="jan_novak@email.sk"
          name="email"
          {...props}
        />
        <InputWithStartIcon
          iconName="password"
          iconType={IconType.gray}
          // placeholder="password"
          placeholder="***********"
          type="password"
          name="password"
          {...props}
        />
      </LabeledInputContainer>
      <div
        className={styles["button-container"]}
        style={{
          borderBottom: "2px solid #eff2f7",
          height: "4.4rem",
          padding: "0.625rem 1.25rem",
        }}
      >
        <Button buttonText="save" />
      </div>
    </div>
  );
};
