import { Icon, IconType } from "../Icon";
import { Link, useLocation } from "react-router-dom";
import styles from "./Navbar.module.scss";
import { Icons } from "../../utils";
import { CALENDAR_PATH, HOME_PATH, PROFILE_PATH, RECYCLING_PATH } from "../../constants";

interface NavbarLink {
  iconName: string;
  path: string;
}

export const Navbar = () => {
  const { pathname } = useLocation();
  const navbarLinks: NavbarLink[] = [
    { iconName: Icons.home, path: HOME_PATH },
    { iconName: Icons.separate, path: RECYCLING_PATH },
    // { iconName: "location", path: "/collection-points" },
    { iconName: Icons.calendar, path: CALENDAR_PATH },
    { iconName: Icons.person, path: PROFILE_PATH },
  ];
  return (
    <nav className={styles.navigation}>
      <ul className={styles.list}>
        {navbarLinks.map(({ iconName, path }, index) => {
          const isActive = pathname.startsWith(path);
          return (
            <li key={index}>
              <Link to={path}>
                <Icon name={iconName} type={isActive ? IconType.green : IconType.default} />
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
