import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../selectors";
import {
  androidExitFullscreen,
  androidGoFullscreen,
  changeDisplayModeHandler,
} from "./bridgeHandler";
import { Colors } from "./themeHandler";
import { CHANGE_PASSWORD_PATH, NOTIFICATION_SETTINGS_PATH } from "../constants";
import { AppDispatch } from "../store";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useSetDisplayMode = () => {
  const user = useSelector(getUser);
  const { pathname } = useLocation();
  const [topColor, setTopColor] = useState<string>(Colors.lightBackground);
  const bottomColor = Colors.white;

  useEffect(() => {
    if (pathname === CHANGE_PASSWORD_PATH || pathname === NOTIFICATION_SETTINGS_PATH) {
      setTopColor(Colors.white);
    } else {
      setTopColor(Colors.lightBackground);
    }
  }, [pathname]);

  useEffect(() => {
    if (user) {
      changeDisplayModeHandler("includeSafeArea", topColor, bottomColor);
      androidExitFullscreen();
    } else {
      changeDisplayModeHandler("noSafeArea");
      androidGoFullscreen();
    }
  }, [user, topColor]);
};

export const useAdjustSafariLayout = () => {
  useEffect(() => {
    // dont account for the navigation bar height in safari iOS
    const setCusomHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    setCusomHeight();
    window.addEventListener("resize", setCusomHeight);

    return () => {
      window.removeEventListener("resize", setCusomHeight);
    };
  }, []);
};
