// import { Info } from "@material-ui/icons";
import styles from "./InfoBox.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

type InfoBoxProps = {
  text: string;
};

export const InfoBox: React.FC<InfoBoxProps> = ({ text }) => {
  const [shown, setShown] = useState(true);

  const handleClose = () => {
    setShown(false);
  };

  return (
    <div className={styles.container}>
      {shown && (
        <div className={styles["info-box"]}>
          <div className={styles["info-icon"]}>
            <InfoIcon fontSize="small" />
          </div>
          <div className={styles.text}>{text}</div>
          <div className={styles["cancel-icon"]} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </div>
        </div>
      )}
    </div>
  );
};
