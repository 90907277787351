import React, { CSSProperties, MouseEventHandler } from "react";

export enum IconType {
  default = "",
  gray = "Gray",
  white = "White",
  light = "Light",
  red = "Red",
  green = "Green",
  blue = "Blue",
  black = "Black",
  orange = "Orange",
}

export type IconProps = {
  name: string;
  type?: IconType;
  size?: string;
  rotation?: string;
  style?: CSSProperties;
  height?: string;
  onClick?: MouseEventHandler;
};

export const Icon: React.FC<IconProps> = ({
  name,
  size,
  rotation,
  type = IconType.default,
  style,
  height,
  onClick,
}) => {
  return (
    <img
      src={`/icons/${name}${type}.svg`}
      alt={name}
      width={size}
      height={height}
      style={{ ...style, transform: `rotate(${rotation})` }}
      onClick={onClick}
    />
  );
};
