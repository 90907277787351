import { BarElement, Chart } from "chart.js";
import React from "react";
import styles from "./VerticalBar.module.scss";

Chart.register(BarElement);

type Props = {
  color: string;
};

export const VerticalBar: React.FC<Props> = ({ color }) => (
  <div className={styles.container} style={{ backgroundColor: color }} />
);
