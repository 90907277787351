import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
}

/**
 * Type predicate to narrow an unknown error's data payload to an object with a string 'message' property
 */
export function isErrorDataWithMessage(data: unknown): data is { message: string } {
  return (
    typeof data === "object" &&
    data != null &&
    "message" in data &&
    typeof (data as any).message === "string"
  );
}

export const getErrorMessageFromHookError = (
  error: FetchBaseQueryError | SerializedError
): string => {
  if (isFetchBaseQueryError(error)) {
    return "error" in error ? error.error : JSON.stringify(error.data);
  }
  return error.message ?? "Unknown error";
};

export const getErrorMessageFromPromise = (error: any): string => {
  if (isFetchBaseQueryError(error)) {
    if ("error" in error) {
      return error.error;
    } else {
      return isErrorDataWithMessage(error.data) ? error.data.message : "Unknown error";
    }
  } else if (isErrorWithMessage(error)) {
    return error.message;
  }
  return "Unknown error";
};

export const getErrorStatusFromPromise = (error: any): number => {
  if (isFetchBaseQueryError(error)) {
    return !("error" in error) ? error.status : 0;
  }
  return 0;
};
