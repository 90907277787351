import { useEffect, useState } from "react";
import { Icons } from ".";
import { IconType } from "../components/Icon";

const ICONS_TO_PRELOAD = [
  `${Icons.home}${IconType.default}`,
  `${Icons.separate}${IconType.default}`,
  `${Icons.calendar}${IconType.default}`,
  `${Icons.person}${IconType.default}`,
  `${Icons.home}${IconType.green}`,
  `${Icons.separate}${IconType.green}`,
  `${Icons.calendar}${IconType.green}`,
  `${Icons.person}${IconType.green}`,
];

export const getIconsToPreload = (): string[] => {
  const icons: string[] = [];
  ICONS_TO_PRELOAD.forEach(icon => icons.push(`/icons/${icon}.svg`));
  return icons;
};

export const usePreloadAssets = (assets: string[]): boolean => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const cacheAssets = async () => {
      const promises = assets.map(asset => {
        return new Promise((resolve, reject) => {
          const img = new Image();

          img.onload = () => resolve(img);
          img.onerror = () => reject(asset);
          img.src = asset;
        });
      });

      try {
        await Promise.all(promises);
        setIsLoading(false);
      } catch {
        setIsLoading(true);
      }
    };

    cacheAssets();
  }, []);

  return isLoading;
};
