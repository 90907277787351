import { Languages } from "./enums";

export const DEFAULT_LANG = Languages.sk;

export function getPreferredLanguage(): Languages {
  const browserLocales =
    navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return DEFAULT_LANG;
  }
  const browserLocalesCodes = browserLocales.map(locale => {
    const trimmedLocale = locale.trim();
    return trimmedLocale.split(/-|_/)[0];
  });

  const preferedLanguage = browserLocalesCodes[0];

  if (Object.keys(Languages).includes(preferedLanguage)) return preferedLanguage as Languages;
  return DEFAULT_LANG;
}
