import React from "react";
import { ContentWithTitle } from "../ContentWithTitle";
import { useTranslation } from "react-i18next";
import { BoxWithDividerLines } from "../BoxWithDividerLines";
import { Link } from "react-router-dom";
import { ContainerInfo, TextNature } from "../../types";
import { containerTypesArray, getWasteTypeName, Icons } from "../../utils";
import { RowWithIconContainer } from "../RowWithIconContainer";
import { WEIGHT_HISTORY_PATH } from "../../constants";

interface ContainerColorized extends ContainerInfo {
  color: string;
}

type ContainersInfoProps = {
  containers: ContainerColorized[];
};

export const MyContainersInfo: React.FC<ContainersInfoProps> = ({ containers }) => {
  const { t } = useTranslation();

  const containersBoxItems = containers.map(({ type, id, wasteSum, wasteType, color }) => {
    const mainText =
      type !== 0
        ? t(`enums.containerTypes.${containerTypesArray[type - 1]}`) +
          " " +
          t(`enums.wasteTypes.${getWasteTypeName(wasteType)}`)
        : "";
    const secondaryText = wasteSum ? `${wasteSum} kg` : "0 kg";
    return {
      id,
      element: (
        <Link to={`${WEIGHT_HISTORY_PATH}/${id}`}>
          <RowWithIconContainer
            iconName={Icons.customMenuTrash}
            isSvg={true}
            mainText={mainText}
            secondaryText={secondaryText}
            svgColorPrimary={color}
            rightIconName={Icons.arrowRight}
            secondaryTextNature={TextNature.info}
            style={{ height: "3rem" }}
          />
        </Link>
      ),
    };
  });

  return (
    <ContentWithTitle title={t("myContainers")}>
      <BoxWithDividerLines items={containersBoxItems} />
    </ContentWithTitle>
  );
};
