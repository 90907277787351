import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "../../types";
import styles from "./SelectComponent.module.scss";

/**
 * Styles for border of the SelectComponent's menu items.
 */
const SELECT_ITEM_BORDER_STYLE = "1px solid #eff2f7";

interface SelectComponentProps {
  customCssClasses?: string[];
  value: ISelectOption["value"];
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  optionsArray: ISelectOption[];
  translate?: boolean;
}

export const SelectComponent: React.FC<SelectComponentProps> = ({
  customCssClasses,
  value,
  optionsArray,
  onChange,
  translate = true,
}) => {
  const { t } = useTranslation();

  let appliedFormControlClassNamesArray = [styles.container];
  if (customCssClasses) {
    appliedFormControlClassNamesArray = appliedFormControlClassNamesArray.concat(
      customCssClasses.map(customClass => styles[customClass])
    );
  }

  return (
    <div className={appliedFormControlClassNamesArray.join(" ")}>
      <FormControl>
        <Select
          value={value}
          onChange={onChange}
          className={styles.select}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiList-root": {
                  maxHeight: "200px",
                },
              },
            },
          }}
        >
          {optionsArray.map((item, i) => (
            <MenuItem
              value={item.value}
              key={item.value}
              sx={{
                borderBottom: `${
                  i === optionsArray.length - 1 ? "none" : SELECT_ITEM_BORDER_STYLE
                }`,
                borderTop: `${i === 0 ? "none" : SELECT_ITEM_BORDER_STYLE}`,
              }}
              className={styles.menuItem}
            >
              {translate ? t(item.label) : item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
