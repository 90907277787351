import React from "react";
import { HomeStatistics } from "../../HomeStatistics";

import { SummaryLineChart } from "../../SummaryLineChart";
import { useTranslation } from "react-i18next";

import styles from "./Home.module.scss";
import {
  useGetSeparationDataQuery,
  useGetWasteCollectionCalendarQuery,
} from "../../../api/citizenApi";
import { LineChartDataItem } from "../../../types";
import { ContentLoader } from "../../ContentLoader";
import { getSeverityFromSeparationRate } from "../../../utils";
import { getCalendarEventsBody, WagonsCalendar } from "../WagonsCalendar";
import { ContentWithTitle } from "../../ContentWithTitle";
import { CALENDAR_PATH } from "../../../constants";

export const Home: React.FC<{}> = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear().toString();

  const { data, isFetching } = useGetSeparationDataQuery(currentYear);
  const { data: calendarEvents } = useGetWasteCollectionCalendarQuery(getCalendarEventsBody());

  if (isFetching || data === undefined) return <ContentLoader />;

  let chartData: LineChartDataItem[] = [];
  const auxDataArray: LineChartDataItem[] = [];
  if (data !== undefined && data !== null) {
    for (const entry of data.wasteSummaryDetail) {
      if (chartData.length > 0 && chartData[chartData.length - 1].month + 1 !== entry.month) {
        auxDataArray.push({
          month: Number(entry.month),
          value: entry.value,
        });
      } else {
        chartData.push({
          month: Number(entry.month),
          value: entry.value,
        });
      }
    }

    chartData = auxDataArray.concat(chartData);
  }

  const percClass =
    styles[
      `${getSeverityFromSeparationRate(data.municipalityDegreeOfSeparation / 100).toString()}-text`
    ];

  return (
    <React.Fragment>
      <div className={styles.graphContainer}>
        <div className={styles.title}>
          <h3>{t("myTownSeparation")}</h3>
          <span>
            {t("year")} {currentYear}
          </span>
          <h2 className={percClass}>{data.municipalityDegreeOfSeparation} %</h2>
        </div>
        <SummaryLineChart
          data={chartData}
          municipalityDegreeOfSeparation={data.municipalityDegreeOfSeparation}
        />
      </div>
      <HomeStatistics
        year={currentYear}
        houseHoldMembers={data.householdMembersCount}
        wasteCount={data.wasteCount}
        separationPerc={data.separationPerc}
        wasteCountPerPerson={data.wasteCountPerPerson}
      />
      {calendarEvents && calendarEvents.length > 0 && (
        <ContentWithTitle
          title={t("wagonsCalendar")}
          linkText={t("allWagons")}
          linkTo={CALENDAR_PATH}
        >
          <WagonsCalendar maxEventsCount={5} />
        </ContentWithTitle>
      )}
      {/* <Receivables /> */}
    </React.Fragment>
  );
};
