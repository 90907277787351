import { Theme, createTheme } from "@mui/material";
import { ApplicationMode } from "../types";

export const Colors = {
  lightBackground: "#eff2f7",
  darkBackground: "#191919",
  white: "#ffffff",
  negative: "#FF5A55",
  positive: "#19B932",
};

const lightTheme = createTheme({
  // @ts-ignore
  MuiInput: {
    root: {
      fontWeight: 500,
    },
  },
  MuiMenuItem: {
    root: {
      fontWeight: 500,
    },
  },
  typography: {
    fontFamily: ["Sora"].join(","),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  palette: {
    primary: {
      light: "#de1919",
      main: "#000",
      dark: "#de1919",
      contrastText: "#fff",
    },
    info: {
      main: "#88A6D1",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: Colors.positive,
          color: Colors.white,
          borderRadius: "9px",
        },
        standardError: {
          backgroundColor: Colors.negative,
          color: Colors.white,
          borderRadius: "9px",
        },
      },
    },
  },
});

const darkTheme = createTheme({
  // @ts-ignore
  MuiPickersDay: {
    daySelected: {
      backgroundColor: "grey",
    },
  },
  MuiPickersClockPointer: {
    thumb: {
      opacity: 0.6,
    },
  },
  MuiInput: {
    root: {
      fontWeight: 500,
    },
  },
  MuiMenuItem: {
    root: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      light: "#de1919",
      main: "#FFF",
      dark: "#de1919",
      contrastText: "#fff",
    },
    background: {
      paper: "#212121",
      default: "#191919",
    },
  },
  typography: {
    fontFamily: ["Sora"].join(","),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
});

export const themeHandler = (applicationMode: ApplicationMode): Theme =>
  applicationMode === ApplicationMode.light ? lightTheme : darkTheme;

export const setBodyBackground = (applicationMode: ApplicationMode) => {
  document.body.style.backgroundColor =
    applicationMode === ApplicationMode.light ? "#eff2f7" : "#191919";
};
