import { FcmEntry, RequestingProgress } from ".";
import { Languages } from "../utils";

export interface UserState extends RequestingProgress {
  user: User | null;
}

export interface User {
  id: number;
  name: string;
  language: Languages;
  devices: FcmEntry[];
}

export const defaultNotificationTime = 12;
export const defaultNotificationAheadTime = 24;
