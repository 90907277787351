import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import {
  ArcElement,
  BubbleDataPoint,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  Plugin,
  ScatterDataPoint,
  Tooltip,
} from "chart.js";
import { AnyObject } from "chart.js/types/basic";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styles from "./SummaryDoughnutChart.module.scss";
import { WasteGraphSingleYear } from "../../types";
import { WasteTypes } from "../../utils";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const options: ChartOptions<"doughnut"> = {
  cutout: "58%",
  radius: 125,
  interaction: {
    mode: "point",
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: false,
    },
  },
  layout: {
    padding: 30,
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

export interface CollectedWasteGraphProps {
  data: WasteGraphSingleYear[];
}

export const SummaryDoughnutChart: React.FC<CollectedWasteGraphProps> = ({ data: detailData }) => {
  const { t } = useTranslation();

  const graphData = detailData.map(item => item.perc);
  const bgColors = detailData.map(item => item.color);

  const recycledPercentage = detailData
    .filter(item => item.type !== WasteTypes.mixed)
    .reduce((sum, item) => (sum += item.perc), 0);

  const plugins: Plugin<"doughnut", AnyObject>[] = [
    {
      id: "doughnutChart",
      afterDraw: (chart: any): void => {
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;

        ctx.restore();

        const headerFontSize = (height / 160).toFixed(2);
        ctx.font = "600 " + headerFontSize + "em Sora";
        ctx.textBaseline = "center";

        const header = `${String(recycledPercentage)} %`;

        const headerX = Math.round((width - ctx.measureText(header).width) / 2);
        const headerY = height / 2 + 5;
        ctx.fillText(header, headerX, headerY);

        ctx.restore();
        const secondaryTextFontSize = (height / 400).toFixed(2);
        ctx.font = secondaryTextFontSize + "em Sora";
        ctx.textBaseline = "center";

        const text = t("recycled").toLowerCase();
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = headerY + 16;
        ctx.fillText(text, textX, textY);

        ctx.save();
      },
    },
  ];

  const data: ChartData<
    "doughnut",
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  > = {
    datasets: [
      {
        label: "",
        data: graphData,
        backgroundColor: bgColors,
      },
    ],
  };

  return (
    <div className={styles.container}>
      {/* {totalWaste === 0 ? (
        <NoResultsOverlay suggestion="suggestionMissingData" errorText="errorPleaseEnterData" />
      ) : ( */}
      <>
        <div className={styles.doughnutChartContainer}>
          <Doughnut
            className={styles.doughnutChart}
            data={data}
            options={options}
            plugins={plugins}
            redraw={true}
          />
        </div>
      </>
      {/* )} */}
    </div>
  );
};
