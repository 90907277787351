import { RootState } from "../types";
import { getDeviceSpecificFcmEntry } from "../utils";

export const getUser = (state: RootState) => state.user.user;
export const getUserStatus = (state: RootState) => state.user.status;
export const getUserErrorMessage = (state: RootState) => state.user.errorMessageKey;
export const getCurrentDeviceFcmEntry = (state: RootState) => {
  if (!state.user.user || !state.application.deviceToken) return null;
  return getDeviceSpecificFcmEntry(state.user.user.devices, state.application.deviceToken);
};
