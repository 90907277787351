import { Images, getMobileOS } from "../../utils";
import { Image } from "../Image";
import styles from "./PlatformStoreImage.module.scss";

export const PlatformStoreImage = () => {
  switch (getMobileOS()) {
    case "iOS":
      return (
        <a href="https://apps.apple.com/us/app/ewa-e-waste-analytics/id6443489724">
          <Image
            imageName={Images.apple_badge}
            generateDensityDescriptors={true}
            className={styles["download-image"]}
          />
        </a>
      );
    case "Android":
      return (
        <a href="https://play.google.com/store/apps/details?id=com.koala42.ewa">
          <Image
            imageName={Images.google_play_badge}
            generateDensityDescriptors={true}
            className={styles["download-image"]}
          />
        </a>
      );
    default:
      return (
        <>
          <a href="https://apps.apple.com/us/app/ewa-e-waste-analytics/id6443489724">
            <Image
              imageName={Images.apple_badge}
              generateDensityDescriptors={true}
              className={styles["download-image"]}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.koala42.ewa">
            <Image
              imageName={Images.google_play_badge}
              generateDensityDescriptors={true}
              className={styles["download-image"]}
            />
          </a>
        </>
      );
  }
};
