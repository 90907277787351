import { useTranslation } from "react-i18next";
import { GeneralTitle } from "../../components/GeneralTitle";
import { Layout } from "../../components/Layout";
import { Navbar } from "../../components/Navbar";
import { WagonsCalendar } from "../../components/PagesContent/WagonsCalendar";

export const WagonsCalendarPage = () => {
  const { t } = useTranslation();

  const topBar = (
    <GeneralTitle
      mainText={t("wagonsCalendar")}
      secondaryText={new Date().getFullYear().toString()}
      rightComponent={<div />}
    />
  );
  const navBar = <Navbar />;

  return (
    <Layout topBar={topBar} navBar={navBar}>
      <WagonsCalendar />
    </Layout>
  );
};
