import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { languagesArray, Languages } from "../../../utils";
import { BoxWithDividerLines } from "../../BoxWithDividerLines";
import { useUpdateUserMutation } from "../../../api/citizenApi";
import { getUser } from "../../../selectors";
import { Button } from "../../Button";
import { ContentWithTitle } from "../../ContentWithTitle";
import { Icon } from "../../Icon";
import styles from "./ChangeLanguage.module.scss";

export const ChangeLanguage = () => {
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const [updateUser] = useUpdateUserMutation();
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelect = (lang: string) => {
    setSelectedLang(lang);
  };

  const handleSubmit = () => {
    updateUser({ id: user!.id, language: languagesArray.indexOf(selectedLang as Languages) })
      .unwrap()
      .then(() => {
        i18n.changeLanguage(selectedLang);
        enqueueSnackbar(t("snackbarMessages.languageChangeSuccess"), { variant: "success" });
        navigate(-1);
      })
      .catch(() => {
        enqueueSnackbar(t("snackbarMessages.actionFailure"), { variant: "error" });
      });
  };

  const sortedLanguages = [i18n.language].concat(
    languagesArray.filter(code => code !== i18n.language)
  );

  const languageBoxItems = sortedLanguages.map((lang, index) => {
    const langItemClasses = [styles.languageItem];
    if (selectedLang === lang) {
      langItemClasses.push(styles.selectedItem);

      return {
        id: index,
        element: (
          <div className={langItemClasses.join(" ")} onClick={() => handleSelect(lang)}>
            {t(lang)}
            <Icon name="check" />
          </div>
        ),
      };
    }
    return {
      id: index,
      element: (
        <div className={langItemClasses.join(" ")} onClick={() => handleSelect(lang)}>
          {t(lang)}
        </div>
      ),
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.contentWithPadding}>
        <ContentWithTitle title={t("languages")}>
          <BoxWithDividerLines
            style={{ padding: "0.6em", paddingLeft: "1rem" }}
            items={languageBoxItems}
          />
        </ContentWithTitle>
      </div>
      <div className={styles.buttonContainer}>
        <Button buttonText={t("save")} onClick={handleSubmit} />
      </div>
    </div>
  );
};
