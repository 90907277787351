import { useDispatch, useSelector } from "react-redux";
import { setSelectedYear } from "../../reducers";
import { getSelectedYear } from "../../selectors";
import { lastYears } from "../../utils";
import { SelectComponent } from "./SelectComponent";

export const YearSelect = () => {
  const selectedYear = useSelector(getSelectedYear);
  const dispatch = useDispatch();

  return (
    <SelectComponent
      value={selectedYear}
      onChange={event => dispatch(setSelectedYear(event.target.value))}
      optionsArray={lastYears}
      customCssClasses={["no-border", "smaller-height"]}
    />
  );
};
