import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../selectors";
import styles from "./HomeTitle.module.scss";
import { useTranslation } from "react-i18next";

export const HomeTitle: React.FC<{}> = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t("hello")}</h1>
      <div className={styles.username}>{user?.name}</div>
      {/* <div className={styles.notifications}>
        <NotificationButton />
      </div> */}
    </div>
  );
};
