import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdatePasswordMutation } from "../../../api/citizenApi";
import { LOGIN_PATH } from "../../../constants";
import { ValueObject } from "../../../types";
import {
  getErrorStatusFromPromise,
  useResetPasswordValidation,
  useValueObject,
} from "../../../utils";
import { AuthBoxHeader } from "../../AuthBoxHeader";
import { Box } from "../../Box";
import { Button } from "../../Button";
import { IconType } from "../../Icon";
import { InputWithStartIcon } from "../../InputWithStartIcon";
import { LabeledInputContainer } from "../../LabeledInputContainer";
import styles from "./ResetPassword.module.scss";

const initialValueObject: ValueObject = {
  password: "",
  passwordAgain: "",
};

export const ResetPassword = () => {
  const { t } = useTranslation();
  const props = useValueObject(initialValueObject);
  const inputErr = useResetPasswordValidation(props.valueObject);
  const [resetPassword] = useUpdatePasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { resetPasswordToken } = useParams();
  const navigate = useNavigate();

  const canSubmit =
    !inputErr.passwordErr.error && !inputErr.passwordAgainErr.error && props.valueObject.password;

  const resetPasswordHandler = () => {
    const resetPasswordBody = {
      password: props.valueObject.password,
      token: resetPasswordToken ?? "",
    };
    resetPassword(resetPasswordBody)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t("snackbarMessages.resetPasswordSuccess"), { variant: "success" });
        navigate(LOGIN_PATH);
      })
      .catch(error => {
        if (getErrorStatusFromPromise(error) === 404) {
          enqueueSnackbar(t("snackbarMessages.resetPasswordFailureNotFound"), { variant: "error" });
        } else if (getErrorStatusFromPromise(error) === 400) {
          enqueueSnackbar(t("snackbarMessages.resetPasswordFailureExpired"), { variant: "error" });
        }
      });
  };

  return (
    <Box className={styles["reset-container"]}>
      <AuthBoxHeader
        title={t("resetPasswordTitle")}
        linkText={t("backToLogin")}
        linkTo={LOGIN_PATH}
      />
      <LabeledInputContainer title={["password", "passwordAgain"]} customClass="no-padding">
        <InputWithStartIcon
          iconName="password"
          iconType={IconType.gray}
          placeholder="•••••••••"
          type="password"
          name="password"
          {...props}
          {...inputErr.passwordErr}
        />
        <InputWithStartIcon
          iconName="password"
          iconType={IconType.gray}
          placeholder="•••••••••"
          type="password"
          name="passwordAgain"
          {...props}
          {...inputErr.passwordAgainErr}
        />
      </LabeledInputContainer>
      <Button
        buttonText={t("resetPassword")}
        onClick={resetPasswordHandler}
        disabled={!canSubmit || resetPasswordToken === undefined}
      />
    </Box>
  );
};
