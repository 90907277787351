export interface ApplicationState {
  breakPoint: ApplicationBreakpoint;
  mode: ApplicationMode;
  selectedYear: string;
  selectedMonth: string;
  deviceToken: string | null;
}

export enum ApplicationBreakpoint {
  desktop = "desktop",
  mobile = "mobile",
}

export enum ApplicationMode {
  light = "light",
  dark = "dark",
}
