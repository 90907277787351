import React from "react";
import { Link } from "react-router-dom";
import styles from "./ContentWithTitle.module.scss";

interface ContentWithTitleProps {
  title: string;
  linkText?: string;
  linkTo?: string;
}
export const ContentWithTitle: React.FC<ContentWithTitleProps> = ({
  title,
  children,
  linkText,
  linkTo,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles["title-container"]}>
        <h2 className={styles.heading}>{title}</h2>
        {linkText && linkTo && (
          <Link to={linkTo}>
            <h3 className={styles.link}>{linkText}</h3>
          </Link>
        )}
      </div>
      {children}
    </div>
  );
};
