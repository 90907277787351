import { Status, User, UserState } from "../types";
import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { citizenApi } from "../api/citizenApi";
import {
  removeJwtFromLocalStorage,
  setJwtToLocalStorage,
  languagesArray,
  Languages,
  getDeviceSpecificFcmEntry,
} from "../utils";
import i18next from "i18next";
import { RootState } from "../store";

const initUserState: UserState = { status: Status.idle, user: null, errorMessageKey: undefined };

export const userSlice = createSlice({
  name: "user",
  initialState: initUserState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state: UserState, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // LOGIN
      .addMatcher(citizenApi.endpoints.loginUser.matchFulfilled, (state, { payload }) => {
        removeJwtFromLocalStorage();
        setJwtToLocalStorage(payload.token);
        state.user = {
          ...payload,
          language: languagesArray[payload.language] as Languages,
        };
        i18next.changeLanguage(languagesArray[payload.language]);
        state.status = Status.success;
      })
      .addMatcher(citizenApi.endpoints.loginUser.matchRejected, state => {
        state.status = Status.error;
        state.errorMessageKey = "wrongCredentialsError";
      })

      // WHOAMI
      .addMatcher(citizenApi.endpoints.whoami.matchFulfilled, (state, { payload }) => {
        state.user = {
          ...payload,
          language: languagesArray[payload.language] as Languages,
        };
        i18next.changeLanguage(languagesArray[payload.language]);
        state.status = Status.success;
      })
      .addMatcher(citizenApi.endpoints.whoami.matchRejected, state => {
        state.status = Status.error;
        state.errorMessageKey = "unloggedUserError";
      })

      // TOKEN_REFRESH
      .addMatcher(citizenApi.endpoints.getRefreshedToken.matchFulfilled, (_, { payload }) => {
        removeJwtFromLocalStorage();
        setJwtToLocalStorage(payload.token);
      })
      .addMatcher(citizenApi.endpoints.getRefreshedToken.matchRejected, state => {
        state.status = Status.error;
        state.errorMessageKey = "refreshTokenError";
      })

      // CHANGE_PASSWORD
      .addMatcher(citizenApi.endpoints.changePassword.matchRejected, state => {
        state.status = Status.error;
        state.errorMessageKey = "changePasswordError";
      })

      // FCM_ENTRY_CREATE
      .addMatcher(citizenApi.endpoints.createFcmTokenEntry.matchRejected, state => {
        state.status = Status.error;
        state.errorMessageKey = "createFcmEntryError";
        state.user = null;
        removeJwtFromLocalStorage();
      })

      .addMatcher(
        isAnyOf(
          citizenApi.endpoints.createFcmTokenEntry.matchFulfilled,
          citizenApi.endpoints.changePassword.matchFulfilled
        ),
        state => {
          state.status = Status.success;
        }
      )

      .addMatcher(
        isAnyOf(
          citizenApi.endpoints.createFcmTokenEntry.matchPending,
          citizenApi.endpoints.changePassword.matchPending,
          citizenApi.endpoints.getRefreshedToken.matchPending,
          citizenApi.endpoints.whoami.matchPending,
          citizenApi.endpoints.loginUser.matchPending
        ),
        state => {
          state.status = Status.requesting;
        }
      );
  },
});

export const { setUser } = userSlice.actions;

export const userReducer = userSlice.reducer;

export const logoutThunk = createAsyncThunk<
  unknown,
  null,
  { state: RootState; rejectValue: string }
>("user/logoutThunk", async (_params, { dispatch, getState }) => {
  const state = getState();
  const user = state.user.user;
  const deviceToken = state.application.deviceToken;

  if (user && deviceToken) {
    const currentDeviceFcmEntry = getDeviceSpecificFcmEntry(user.devices, deviceToken);

    if (currentDeviceFcmEntry) {
      await dispatch(
        citizenApi.endpoints.unlogFcmEntry.initiate(currentDeviceFcmEntry.ownersDeviceId)
      );
    }
  }

  dispatch(setUser(null));
  removeJwtFromLocalStorage();
  dispatch(citizenApi.util.resetApiState());
  return null;
});
