import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../Box";
import { ContentWithTitle } from "../ContentWithTitle";
import { RowWithIconContainer } from "../RowWithIconContainer";
import { getSeverityFromSeparationRate, Icons } from "../../utils";

export type HomeStatisticsProps = {
  year: string;
  houseHoldMembers: number;
  wasteCount: number;
  wasteCountPerPerson: number;
  separationPerc: number;
};

export const HomeStatistics: React.FC<HomeStatisticsProps> = ({
  year,
  houseHoldMembers,
  wasteCount,
  wasteCountPerPerson,
  separationPerc,
}) => {
  const { t } = useTranslation();

  return (
    <ContentWithTitle title={t("statistics")}>
      <Box>
        <RowWithIconContainer
          iconName={Icons.recycle}
          mainText={t("mySeparation")}
          secondaryText={`${t("year")} ${year}`}
          rightText={`${Math.floor(separationPerc)} %`}
          rightTextNature={getSeverityFromSeparationRate(separationPerc / 100)}
        />
      </Box>
      <Box>
        <RowWithIconContainer
          iconName={Icons.members}
          mainText={t("memberCount")}
          secondaryText={t("myHousehold")}
          rightText={`${houseHoldMembers}`}
        />
      </Box>
      {houseHoldMembers > 1 && (
        <Box>
          <RowWithIconContainer
            iconName={Icons.weight}
            mainText={t("wasteWeight")}
            secondaryText={t("personPerYear")}
            rightText={`${Math.floor(wasteCountPerPerson)} kg`}
          />
        </Box>
      )}
      <Box>
        <RowWithIconContainer
          iconName={Icons.weightOverall}
          mainText={t("wasteWeight")}
          secondaryText={t("summary")}
          rightText={`${Math.floor(wasteCount)} kg`}
        />
      </Box>
    </ContentWithTitle>
  );
};
