import { useNavigate } from "react-router-dom";
import styles from "./AuthBoxHeader.module.scss";

interface AuthBoxHeaderProps {
  title: string;
  linkText?: string;
  linkTo?: string;
}

export const AuthBoxHeader: React.FC<AuthBoxHeaderProps> = ({ title, linkText, linkTo }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.titleContainer}>
      <h1>{title}</h1>
      {linkText && linkTo && <a onClick={() => navigate(linkTo)}>{linkText}</a>}
    </div>
  );
};
