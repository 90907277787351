import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ErrorObject, ValueObject } from "../types";
import validator from "validator";

export const initialErrorObject: Readonly<ErrorObject> = {
  error: false,
  errorMessage: "",
};

// https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render?answertab=scoredesc#tab-top
function useDidUpdateEffect(fn: () => any, inputs: any[]) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
}

export const useValueObject = (initialValueObject: ValueObject) => {
  const [valueObject, setValueObject] = useState<ValueObject>(initialValueObject);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueObject({ ...valueObject, [e.target.name]: e.target.value });
  };

  const resetValues = () => {
    setValueObject(initialValueObject);
  };

  return { valueObject, handleChange, resetValues };
};

export const useCheckEmptyField = (field: string) => {
  const { t } = useTranslation();

  const [error, setError] = useState(initialErrorObject);

  useDidUpdateEffect(() => {
    if (field === "") {
      setError({ error: true, errorMessage: t("enterTheVluePlease") });
    } else {
      setError({ error: false, errorMessage: "" });
    }
  }, [field, t]);

  return error;
};

export const useValidateEmail = (
  valueObject: ValueObject,
  setError: React.Dispatch<React.SetStateAction<ErrorObject>>
) => {
  const { t } = useTranslation();

  useDidUpdateEffect(() => {
    if (!validator.isEmail(valueObject.email)) {
      setError({ error: true, errorMessage: t("notAnEmail") });
    } else {
      setError(initialErrorObject);
    }
  }, [valueObject.email, t]);
};

export const useValidatePassword = (
  valueObject: ValueObject,
  setError: React.Dispatch<React.SetStateAction<ErrorObject>>
) => {
  const { t } = useTranslation();

  useDidUpdateEffect(() => {
    if (valueObject.password.length < 6) {
      setError({ error: true, errorMessage: t("shortPassword") });
    } else if (valueObject.password.length > 64) {
      setError({ error: true, errorMessage: t("longPassword") });
    } else {
      setError(initialErrorObject);
    }
  }, [valueObject.password, t]);
};

export const useValidatePasswordOriginality = (
  valueObject: ValueObject,
  setError: React.Dispatch<React.SetStateAction<ErrorObject>>
) => {
  const { t } = useTranslation();

  useDidUpdateEffect(() => {
    if (valueObject.password.length >= 6 && valueObject.password.length <= 64) {
      if (valueObject.currentPassword === valueObject.password) {
        setError({ error: true, errorMessage: t("samePasswords") });
      } else {
        setError(initialErrorObject);
      }
    }
  }, [valueObject.currentPassword, valueObject.password, t]);
};

export const useValidatePasswordConfirmation = (
  valueObject: ValueObject,
  setError: React.Dispatch<React.SetStateAction<ErrorObject>>
) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (valueObject.password !== "" || valueObject.passwordAgain !== "") {
      if (valueObject.password !== valueObject.passwordAgain) {
        setError({
          error: true,
          errorMessage: t("passwordsDoNotMatch"),
        });
      } else {
        setError(initialErrorObject);
      }
    } else {
      setError(initialErrorObject);
    }
  }, [valueObject.password, valueObject.passwordAgain, t]);
};

export const useValidatePasswordChange = (valueObject: ValueObject) => {
  const [passwordErr, setPasswordErr] = useState(initialErrorObject);
  const [passwordAgainErr, setPasswordAgainErr] = useState(initialErrorObject);

  useValidatePassword(valueObject, setPasswordErr);
  useValidatePasswordOriginality(valueObject, setPasswordErr);
  useValidatePasswordConfirmation(valueObject, setPasswordAgainErr);

  return {
    currentPasswordErr: initialErrorObject,
    passwordErr,
    passwordAgainErr,
  };
};

export const useRegisterInputValidation = (valueObject: ValueObject) => {
  const [emailErr, setEmailErr] = useState(initialErrorObject);
  const [passwordErr, setPasswordErr] = useState(initialErrorObject);
  const [passwordAgainErr, setPasswordAgainErr] = useState(initialErrorObject);

  useValidatePassword(valueObject, setPasswordErr);
  useValidatePasswordOriginality(valueObject, setPasswordErr);
  useValidatePasswordConfirmation(valueObject, setPasswordAgainErr);
  useValidateEmail(valueObject, setEmailErr);

  return {
    emailErr,
    passwordErr,
    passwordAgainErr,
    setPasswordAgainErr,
  };
};

export const useResetPasswordValidation = (valueObject: ValueObject) => {
  const [passwordErr, setPasswordErr] = useState(initialErrorObject);
  const [passwordAgainErr, setPasswordAgainErr] = useState(initialErrorObject);

  useValidatePassword(valueObject, setPasswordErr);
  useValidatePasswordConfirmation(valueObject, setPasswordAgainErr);

  return {
    passwordErr,
    passwordAgainErr,
  };
};

export const useAskForPasswordResetValidation = (valueObject: ValueObject) => {
  const [emailErr, setEmailErr] = useState(initialErrorObject);
  useValidateEmail(valueObject, setEmailErr);

  return {
    emailErr,
  };
};
