import { WasteCollectionCalendarRequest } from "../../../types";
import { getEndOfYearDate } from "../../../utils";

export const getCalendarEventsBody = (): WasteCollectionCalendarRequest => {
  const currentDate = new Date();
  return {
    startDate: currentDate.toDateString(),
    endDate: getEndOfYearDate(currentDate.getFullYear()).toISOString(),
  };
};
