export const BASE_URL = "https://api.citizen.ewama.sk/v1";

export const USER_URL = "/owners";
export const POST_LOGIN_URL = "/owners/login";
export const POST_REGISTER_URL = "/owners/register";
export const UPDATE_PASSWORD_URL = "/owners/password";
export const GET_WHOAMI = "/owners/whoami";
export const GET_REFRESHED_TOKEN = "/owners/refreshToken";
export const GET_SEPARATION_DATA_URL = "/locations";
export const GET_WASTE_DETAIL_DATA_URL = "/wastes";
export const GET_CONTAINER_COLLECTIONS_URL = "/containers";
export const POST_RESRET_PASSWORD_URL = "/owners/passwordReset";
export const POST_UPDATE_PASSWORD_URL = "/owners/passwordUpdate";
export const WASTE_COLLECTION_CALENDAR_URL = "/waste-collection-calendar-citizen";
export const NOTIFICATIONS_TOKEN_URL = "/owners/device";
