import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon, IconType } from "../Icon";
import styles from "./NavigateBackWithContent.module.scss";

type NavigateBackWithContentProps = {
  rightComponent: JSX.Element;
};

export const NavigateBackWithContent: React.FC<NavigateBackWithContentProps> = ({
  rightComponent,
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon name="arrowLeft" type={IconType.default} onClick={goBack} />
      </div>
      <div>{rightComponent}</div>
    </div>
  );
};
