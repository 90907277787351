import React from "react";
import { LogoutControls } from "../../LogoutControls";
import { ProfileControls } from "../../ProfileControls";

export const ProfileOverview = () => {
  return (
    <React.Fragment>
      <ProfileControls />
      {/* <AccessibilityControls /> */}
      <LogoutControls />
    </React.Fragment>
  );
};
