import { createListenerMiddleware, TypedStartListening } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { RootState } from "../types";
import { apiListener } from "./apiListener";

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const indexListener = () => {
  apiListener();
};
