import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  BASE_URL,
  GET_CONTAINER_COLLECTIONS_URL,
  GET_REFRESHED_TOKEN,
  GET_SEPARATION_DATA_URL,
  GET_WASTE_DETAIL_DATA_URL,
  GET_WHOAMI,
  NOTIFICATIONS_TOKEN_URL,
  POST_LOGIN_URL,
  POST_REGISTER_URL,
  POST_RESRET_PASSWORD_URL,
  POST_UPDATE_PASSWORD_URL,
  UPDATE_PASSWORD_URL,
  USER_URL,
  WASTE_COLLECTION_CALENDAR_URL,
} from "../constants";
import {
  ChangePasswordRequest,
  ContainerCollectionsRequest,
  ContainerCollectionsResponse,
  FcmCreateRequest,
  FcmEntry,
  LoginRequest,
  OwnerLoginResponse,
  OwnerPasswordResetUpdateRequest,
  OwnerResponse,
  RegisterRequest,
  SeparationResponse,
  TokenResponse,
  UpdateFcmRequest,
  UpdateUserRequest,
  WasteCalendarEvent,
  WasteCollectionCalendarRequest,
  WasteCollectionCalendarResponse,
  WasteDetailResponse,
} from "../types";
import { getJwtFromLocalStorage, getWasteTypeName } from "../utils";

export const citizenApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      const token = getJwtFromLocalStorage();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  // The "endpoints" represent operations and requests for this server
  tagTypes: ["User"],
  endpoints: builder => ({
    // USER
    registerUser: builder.mutation<OwnerLoginResponse, RegisterRequest>({
      query: newUser => {
        return {
          url: POST_REGISTER_URL,
          method: "POST",
          body: newUser,
        };
      },
    }),
    loginUser: builder.mutation<OwnerLoginResponse, LoginRequest>({
      query: credentials => {
        return {
          url: POST_LOGIN_URL,
          method: "POST",
          body: credentials,
        };
      },
    }),
    whoami: builder.query<OwnerResponse, any>({
      query: () => {
        return {
          url: GET_WHOAMI,
        };
      },
      providesTags: ["User"],
    }),
    getRefreshedToken: builder.query<TokenResponse, any>({
      query: () => {
        return {
          url: GET_REFRESHED_TOKEN,
        };
      },
    }),
    askForPasswordReset: builder.mutation<null, string>({
      query: email => {
        return {
          url: POST_RESRET_PASSWORD_URL,
          method: "POST",
          body: {
            email,
          },
        };
      },
    }),
    updatePassword: builder.mutation<null, OwnerPasswordResetUpdateRequest>({
      query: resetPasswordBody => {
        return {
          url: POST_UPDATE_PASSWORD_URL,
          method: "POST",
          body: resetPasswordBody,
        };
      },
    }),
    changePassword: builder.mutation<OwnerLoginResponse, ChangePasswordRequest>({
      query: passwords => {
        return {
          url: UPDATE_PASSWORD_URL,
          method: "PATCH",
          body: passwords,
        };
      },
    }),
    updateUser: builder.mutation<any, UpdateUserRequest>({
      query: ({ id, email, language }) => {
        return {
          url: `${USER_URL}/${id}`,
          method: "PATCH",
          body: {
            email,
            language,
          },
        };
      },
      invalidatesTags: ["User"],
    }),

    // FCM PUSH NOTIFICATIONS
    createFcmTokenEntry: builder.mutation<FcmEntry, FcmCreateRequest>({
      query: body => {
        return {
          url: NOTIFICATIONS_TOKEN_URL,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    updateFcmTokenEntry: builder.mutation<FcmEntry, UpdateFcmRequest>({
      query: ({ entryId, token, loggedIn, notificationTime, notificationAheadTime }) => {
        return {
          url: `${NOTIFICATIONS_TOKEN_URL}/${entryId}`,
          method: "PATCH",
          body: { token, loggedIn, notificationTime, notificationAheadTime },
        };
      },
      invalidatesTags: ["User"],
    }),
    unlogFcmEntry: builder.mutation<FcmEntry, number>({
      query: entryId => {
        return {
          url: `${NOTIFICATIONS_TOKEN_URL}/${entryId}`,
          method: "PATCH",
          body: { entryId, loggedIn: false },
        };
      },
    }),
    logInFcmEntry: builder.mutation<FcmEntry, number>({
      query: entryId => {
        return {
          url: `${NOTIFICATIONS_TOKEN_URL}/${entryId}`,
          method: "PATCH",
          body: { entryId, loggedIn: true },
        };
      },
    }),

    // DATA
    getSeparationData: builder.query<SeparationResponse, string>({
      query: year => {
        return {
          url: `${GET_SEPARATION_DATA_URL}/${year}`,
        };
      },
    }),
    getWasteDetailOfYear: builder.query<WasteDetailResponse, string>({
      query: selectedYear => {
        return {
          url: `${GET_WASTE_DETAIL_DATA_URL}/${selectedYear}`,
        };
      },
    }),
    getContainerCollections: builder.query<
      ContainerCollectionsResponse,
      ContainerCollectionsRequest
    >({
      query: collectionsDetail => {
        return {
          url: `${GET_CONTAINER_COLLECTIONS_URL}/${collectionsDetail.id}/${collectionsDetail.year}`,
        };
      },
    }),
    getWasteCollectionCalendar: builder.query<WasteCalendarEvent[], WasteCollectionCalendarRequest>(
      {
        query: ({ startDate, endDate }) => {
          return {
            url: WASTE_COLLECTION_CALENDAR_URL,
            params: {
              startDate,
              endDate,
            },
          };
        },
        transformResponse: (response: WasteCollectionCalendarResponse[]) => {
          return response.map(({ date, wasteType }) => ({
            date,
            wasteType: getWasteTypeName(wasteType),
          }));
        },
      }
    ),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useGetSeparationDataQuery,
  useGetWasteDetailOfYearQuery,
  useChangePasswordMutation,
  useGetContainerCollectionsQuery,
  useWhoamiQuery,
  useGetRefreshedTokenQuery,
  useAskForPasswordResetMutation,
  useUpdatePasswordMutation,
  useUpdateUserMutation,
  useGetWasteCollectionCalendarQuery,
  useCreateFcmTokenEntryMutation,
  useUpdateFcmTokenEntryMutation,
} = citizenApi;
