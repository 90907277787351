import { CustomContentProps, SnackbarContent } from "notistack";
import React from "react";

import styles from "./SnackbarComponent.module.scss";

export const SnackbarComponent = React.forwardRef<HTMLDivElement, CustomContentProps>(
  (props, ref) => {
    const { message, variant } = props;

    const appliedStyles = [styles.container, styles[variant]];
    return (
      <SnackbarContent ref={ref} role="alert" className={appliedStyles.join(" ")}>
        {message}
      </SnackbarContent>
    );
  }
);
