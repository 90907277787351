import { ApplicationBreakpoint, ApplicationMode, ApplicationState } from "../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initApplicationState: ApplicationState = {
  breakPoint: ApplicationBreakpoint.mobile,
  mode: ApplicationMode.light,
  selectedYear: String(new Date().getFullYear()),
  selectedMonth: String(new Date().getMonth() + 1),
  deviceToken: null,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState: initApplicationState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setApplicationBreakPoint: (
      state: ApplicationState,
      action: PayloadAction<ApplicationState["breakPoint"]>
    ) => {
      state.breakPoint = action.payload;
    },
    setSelectedYear: (
      state: ApplicationState,
      action: PayloadAction<ApplicationState["selectedYear"]>
    ) => {
      state.selectedYear = action.payload;
    },
    setSelectedMonth: (
      state: ApplicationState,
      action: PayloadAction<ApplicationState["selectedMonth"]>
    ) => {
      state.selectedMonth = action.payload;
    },
    setDeviceToken: (state: ApplicationState, action: PayloadAction<string>) => {
      state.deviceToken = action.payload;
    },
  },
});

export const {
  setApplicationBreakPoint,
  setSelectedYear,
  setSelectedMonth,
  setDeviceToken,
} = applicationSlice.actions;

export const applicationReducer = applicationSlice.reducer;
