import { Icons } from "../../utils";
import { Icon, IconType } from "../Icon";
import styles from "./NoData.module.scss";

export interface NoDataProps {
  text?: string;
}

export const NoData: React.FC<NoDataProps> = ({ text }) => {
  return (
    <div className={styles.noDataContainer}>
      <Icon name={Icons.offline} type={IconType.default} />
      <p>{text}</p>
    </div>
  );
};
