import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutThunk } from "../../reducers";
import { Icons } from "../../utils";
import { Box } from "../Box";
import { ContentWithTitle } from "../ContentWithTitle";
import { IconType } from "../Icon";
import { RowWithIconContainer, RowContainerProps } from "../RowWithIconContainer";

export const logoutControls: RowContainerProps = {
  iconName: Icons.logout,
  iconType: IconType.gray,
  mainText: "logoutNow",
  rightIconName: "arrowRight",
};

const { iconName, iconType, mainText, rightIconName } = logoutControls;

export const LogoutControls = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutThunk(null));
  };

  return (
    <ContentWithTitle title={t("logout")}>
      <Box style={{ padding: "0.6em", paddingLeft: "1rem" }}>
        <RowWithIconContainer
          style={{ padding: 0 }}
          iconName={iconName}
          iconType={iconType}
          mainText={t(mainText)}
          rightIconName={rightIconName}
          onClick={handleLogout}
        />
      </Box>
    </ContentWithTitle>
  );
};
